// import ReactECharts from 'echarts-for-react';
// import React from 'react';

// const DonutPieChart = ({ dataSet, Tootltiptile }) => {
//   const getOption = () => {
//     const customColors = ['#8927EF', '#E8D1FF', '#EF7ABD', '#7BB2FD', '#F8B133', '#5470c6', '#af6df4', '#f85e86'];

//     const customTooltipFormatter = params => {
//       const name = params?.data?.name;
//       const value = params?.data?.value;
//       const titlesub = Tootltiptile;

//       const backgroundColor = '#8927EF30';
//       const color = '#8927EF';

//       return `
//           <div style="min-width:120px; text-align:center; border-radius: 12px">
//             <p class="medium-text py-1" style="background-color: ${backgroundColor}; color: ${color}; border-radius: 12px 12px 0 0;">
//               ${name}
//             </p>
//             <div class="px-2 flex-column">
//               <label class="flex justify-center items-center flex-1 semibold-text font-12">${value}</label>
//               <label class="flex justify-center items-center flex-1 medium-text font-12">${titlesub}</label>
//             </div>
//           </div>
//       `;
//     };

//     return {
//       tooltip: {
//         trigger: 'item',
//         formatter: customTooltipFormatter,
//         backgroundColor: 'rgba(255, 255, 255, 0.90)',
//         borderRadius: 12,
//         borderWidth: 0,
//         padding: 0,
//       },
//       legend: {
//         orient: 'vertical', // Set orientation to vertical
//         top: '10%',
//         left: 'left',
//         icon: 'circle',
//         textStyle: {
//           color: '#16192C',
//           width: 200,
//           fontSize: '12px',
//           fontFamily: 'Poppins',
//           overflow: 'truncate',
//         },
//       },

//       series: [
//         {
//           type: 'pie',
//           roseType: 'area',
//           center: ['30%', '40%'],
//           labelLine: {
//             show: false,
//           },
//           radius: ['40%', '70%'],
//           avoidLabelOverlap: false,
//           itemStyle: {
//             borderRadius: 12,
//             borderColor: '#fff',
//             borderWidth: 2,
//           },
//           label: {
//             show: true,
//             position: 'center',
//             fontSize: '20px',
//             borderWidth: 1,
//             fontWeight: '500',
//             fontFamily: 'Poppins',
//             formatter: params => {
//               const percentValue = Math.round(params.percent);
//               return `${percentValue}%`;
//             },
//             borderColor: '#E2E7EF',
//             backgroundColor: '#fff',
//             width: 70,
//             height: 70,
//             padding: [15, 15],
//             borderRadius: 70,
//           },

//           data: dataSet.map((item, index) => ({
//             ...item,
//             itemStyle: {
//               color: customColors[index],
//             },
//           })),
//         },
//       ],
//     };
//   };

//   return (
//     <div className="chart-content-box h-full">
//       <ReactECharts
//         className="echart-custom-tooltip"
//         option={getOption()}
//         notMerge={true}
//         lazyUpdate={true}
//         opts={{ renderer: 'svg' }}
//       />
//     </div>
//   );
// };

// export default DonutPieChart;

import ReactECharts from 'echarts-for-react';
import React from 'react';

const DonutPieChart = ({ dataSet, Tootltiptile }) => {
  const getOption = () => {
    const customColors = ['#BA81FF', '#CDA5F7', '#DEBEFF', '#8927EF', '#6424A6', '#5470c6', '#af6df4', '#f85e86'];

    const customTooltipFormatter = params => {
      const lastIndexOfDash = params?.data?.name.lastIndexOf('-');
      const name = lastIndexOfDash > 0 ? params?.data?.name.substring(0, lastIndexOfDash) : params?.data?.name;
      const value = params?.data?.value;
      const titlesub = Tootltiptile;

      const backgroundColor = '#8927EF30';
      const color = '#8927EF';

      return `
            <div style="min-width:120px; text-align:center; border-radius: 12px">
              <p class="medium-text py-1 px-1" style="background-color: ${backgroundColor}; color: ${color}; border-radius: 12px 12px 0 0;">
                ${name}
              </p>
              <div class="px-2 py-1  flex-column">
                <label class="flex justify-center items-center flex-1 semibold-text font-12">${value}</label>
                <label class="flex justify-center items-center flex-1 medium-text font-12">${titlesub}</label>
              </div>
            </div>
        `;
    };

    return {
      tooltip: {
        trigger: 'item',
        formatter: customTooltipFormatter,
        backgroundColor: 'rgba(255, 255, 255, 0.90)',
        borderRadius: 12,
        borderWidth: 0,
        padding: 0,
      },
      legend: {
        orient: 'vertical',
        top: 'center',
        left: 'left',
        itemWidth: 24,
        itemHeight: 8,

        textStyle: {
          color: '#16192C',
          width: 350,
          fontSize: '12px',
          fontFamily: 'Poppins',
          overflow: 'truncate',
          fontWeight: 500,
        },
      },
      series: [
        {
          type: 'pie',
          center: ['78%', '50%'],
          radius: ['50%', '90%'],
          avoidLabelOverlap: false,
          minAngle: 8,
          label: {
            show: true,
            position: 'inside',
            formatter: '{c}',
            color: '#fff',
          },
          labelLine: {
            show: false,
          },
          data: dataSet.map((item, index) => ({
            ...item,
            itemStyle: {
              color: customColors[index],
            },
          })),
        },
      ],
    };
  };

  return (
    <ReactECharts option={getOption()} style={{ width: '100%', height: '400px' }} notMerge={true} lazyUpdate={true} />
  );
};

export default DonutPieChart;
