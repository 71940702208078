import { getItemFromLocalStorage } from './localstorage';

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;
export const onlyNumbers = /^\d+$/;

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function capitalizeFirstLetterOfWords(str) {
  return str.replace(/\b\w/g, char => char.toUpperCase());
}

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const lowerCase = str => {
  if (!str) return '';
  return str.toLowerCase();
};

export const camelCase = str => {
  let ans = str.toLowerCase();
  return ans.split(' ').reduce((s, c) => `${s} ` + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export const replaceTextSymbol = (text, replaceString = '-', replaceWith = '_') => {
  if (!text) return '';
  return text.replaceAll(replaceString, replaceWith);
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions || [];
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
      ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
      : '';
};

export const getInitialsSingleChar = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}`
    : forename
      ? `${forename?.charAt(0)?.toUpperCase() || ''}`
      : '';
};

export const getContactValue = (contacts, contactType) => {
  const contact = contacts?.find(item => item?.contact_type === contactType);
  return contact?.contact_value || 'Not provided';
};

export const getStatus = (startDate, endDate) => {
  const currentDate = Date.now();

  if (startDate > currentDate) {
    return 'PENDING';
  } else {
    return 'CURRENT';
  }
};

export const getCurrencySymbol = currency => {
  switch (currency) {
    case 'USD':
    case 'usd':
      return '$';
    case 'GBP':
    case 'gbp':
      return '£';
    case 'EUR':
    case 'eur':
      return '€';
    default:
      return '$';
  }
};

export const getRecurringPeriod = period => {
  switch (period) {
    case 'YEAR':
      return 'Yearly';
    case 'MONTH':
      return 'Monthly';
    case 'WEEK':
      return 'Weekly';
    case 'DAY':
      return 'Daily';
  }
};

export const getPaymentFrequencyText = data => {
  switch (data) {
    case 'YEAR':
      return 'Annually';
    case 'MONTH':
      return 'Monthly';
    case 'QUARTER':
      return 'Quarterly';
    case 'SINGLE':
      return 'Single';
    case 'DAY':
      return 'Daily';
    default:
      return '';
  }
};

export const handleCopyClick = async url => {
  try {
    await navigator.clipboard.writeText(url);
    return true;
  } catch (error) {
    console.error('Failed to copy:', error);
    return false;
  }
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};

export const checkRoleTier = (currentUserRole, otherUserRole) => {
  const { tier, is_superuser } = currentUserRole || {};
  const { tier: otherTier } = otherUserRole || {};

  if (is_superuser) return true;
  if (!tier || !otherTier) return false;
  return tier < otherTier;
};

export const countMatchesInRegex = (regex, str) => {
  return ((str || '').match(regex) || []).length;
};

export const getFormattedNumber = (number, currency, locale = undefined) => {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency || 'GBP',
    minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(number);
};

export const formatDecimalNumber = (number, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'decimal',
    useGrouping: false,
    minimumFractionDigits, // (this suffices for whole numbers, but will print 2500.10 as 2,500.1)
    maximumFractionDigits, // (causes 2500.99 to be printed as 2,501)
  });
  return formatter.format(number);
};

export const calculatePercentageIncrease = (oldNumber, newNumber) => {
  if (!oldNumber) return 0;
  const increaseValue = ((newNumber - oldNumber) / oldNumber) * 100;
  return formatDecimalNumber(increaseValue, 0, 0);
};
