import { nanoid } from '@reduxjs/toolkit';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { OrganisationContext } from '../../context/organisationContext';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import useDebounce from '../../helpers/useDebounceHook';
import { getPlayerList } from '../../store/features/playerListSlice';
import { addToast } from '../../store/features/toastSlice';
import PageHeader from '../page-header';
import PlayerListItems from './playerListItems';

const PlayerList = ({ expanded }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(0);

  const { t } = useTranslation();

  const { setModal } = useContext(OrganisationContext);

  const { playerList } = useSelector(state => state.playerlist);

  const userOrganization = getItemFromLocalStorage('user')?.organizations?.[0];
  const categoryTag = userOrganization?.category?.tag?.tag;

  const [loading, setLoading] = useState(false);
  const [search, setSearchText] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const getPlayerListData = (page, showLoading = true, merge = false) => {
    setLoading(showLoading);
    dispatch(
      getPlayerList({
        merge,
        params: {
          search: debouncedSearch,
          page: page,
          size: 20,
          status: categoryTag !== 'SAAS_OPERATOR' ? 'ACTIVE,INACTIVE' : null,
        },
      }),
    )
      .then(() => {
        pageRef.current = page;
      })
      .catch(e => {
        dispatch(addToast({ error: true, text: 'Error while fetching player list', id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    getPlayerListData(pageRef.current + 1, false, true);
  };

  useEffect(() => {
    getPlayerListData(0, false);
  }, [debouncedSearch]);

  const onExportClick = () => {
    setModal({ type: 'player-list-export', content: {} });
  };

  return (
    <PlayerListWrapper className="flex flex-1 flex-column mx-10 overflow-hidden py-6 ">
      <div className="flex justify-between items-center pb-8">
        <div className="font-20  medium-text reguler-text w-full">{t('PLAYER_LIST')}</div>
        <PageHeader
          showExportButton={true}
          exportButtonPermission="PLAYER_EXPORT"
          onExportClick={onExportClick}
          showSearch
          searchText={search}
          onSearchChange={setSearchText}
          className="justify-end"
        />
      </div>
      <PlayerListItems search={search} expanded={expanded} playerList={playerList} fetchMoreData={fetchMoreData} />
    </PlayerListWrapper>
  );
};

const PlayerListWrapper = styled.div``;

export default PlayerList;
