import classNames from 'classnames';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/images/close-image.svg';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { useWindowSize } from '../../hooks/useWindowSize';
import { getOrganizationNetworks } from '../../store/features/clientOrgSlice';
import DropDown from '../McDropdown';
import Checkbox from '../common/checkbox';

const OverviewBeneficiaryFilter = ({ filter, setFilter, isMulti = true, isClientCategory = false }) => {
  const dispatch = useDispatch();

  const { client, beneficiary } = filter;

  const [beneficiaryData, setBeneficiaryData] = useState({});
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(beneficiary || (isMulti ? [] : null)); // this is crucial to have state here to avoid re-mounting of the component

  const fetchBeneficiaryList = (page = 0, merge = false) => {
    dispatch(
      getOrganizationNetworks({ organization_id: client.id, params: { page: page, category_tag: 'BENEFICIARY' } }),
    )
      .then(res => {
        const noneOption = isClientCategory ? { name: 'None', id: null } : null;
        const beneficiaryContent = merge
          ? [...beneficiaryData?.content, ...res?.content]
          : [noneOption, ...res?.content].filter(Boolean);
        setBeneficiaryData({ ...res, content: beneficiaryContent });
      })
      .catch(err => {
        setBeneficiaryData({});
      });
  };

  const fetchMoreBeneficiary = () => {
    fetchBeneficiaryList(beneficiaryData?.number + 1, true);
  };

  useEffect(() => {
    fetchBeneficiaryList();

    return () => {
      setBeneficiaryData({});
      setSelectedBeneficiary([]);
    };
  }, [client?.id]);

  const isBeneficiarySelected = option => {
    return selectedBeneficiary?.findIndex(b => b.id === option.id) > -1;
  };

  const onBeneficiaryChange = option => {
    if (!isMulti) {
      setFilter({ client, beneficiary: option?.id ? option : null });
      setSelectedBeneficiary(option?.id ? option : null);
      return;
    }
    let newBeneficiary = [];
    if (isBeneficiarySelected(option)) {
      newBeneficiary = selectedBeneficiary?.filter(b => b.id !== option.id);
    } else {
      newBeneficiary = [...selectedBeneficiary, option];
    }
    setFilter({ client, beneficiary: newBeneficiary });
    setSelectedBeneficiary([...newBeneficiary]);
  };

  return (
    <Fragment>
      <DropDown
        key={'beneficiary'}
        className="mx-2 beneficiary-dropdown"
        options={beneficiaryData?.content || []}
        isPaged={true}
        isSearchable={false}
        pageNumber={beneficiaryData?.number || 0}
        fetchData={fetchMoreBeneficiary}
        setSelected={option => onBeneficiaryChange(option)}
        hasMore={!beneficiaryData?.last || false}
        selected={isMulti ? (selectedBeneficiary?.length ? selectedBeneficiary : null) : selectedBeneficiary}
        placeholder="Select Beneficiary"
        closeOnSelect={false}
        // this is crucial to have useCallback to avoid re-mounting of the component and avoid infinite scroll loop issue
        RenderOption={
          isMulti
            ? useCallback(
                ({ option, handleSelect }) => (
                  <div
                    key={option.id}
                    className={`flex items-center px-2 cursor h-40px mc-option-item overflow-hidden`}
                    onClick={() => handleSelect(option)}>
                    <Checkbox checked={isBeneficiarySelected(option)} />
                    <label className="regular-text flex option-name one-line ml-1 display-inline-block">
                      {option.name}
                    </label>
                  </div>
                ),
                [selectedBeneficiary?.length],
              )
            : null
        }
        renderSelectedValue={
          isMulti
            ? selected => (
                <div className="flex-wrap items-center">
                  {selected?.map((s, i) => (
                    <div key={i} className="flex items-center mr-2 mb-1 radius-1 pxy-1 selected-beneficiary-option">
                      <label className="regular-text flex-1 flex option-name">{s.name}</label>
                      <div
                        className="flex pxy-0_5 radius-1 bg-white cursor ml-1"
                        onClick={e => {
                          e.stopPropagation();
                          onBeneficiaryChange(s);
                        }}>
                        <CloseIcon className="regular-text" width={12} height={12} />
                      </div>
                    </div>
                  ))}
                </div>
              )
            : null
        }
      />
    </Fragment>
  );
};

const RenderClientOption = ({ option, handleSelect, filter, setFilter }) => {
  return (
    <div
      className={classNames(option?.id && filter?.client?.id === option?.id && 'radius-2 my-1 pb-2 selected-client')}>
      <div
        key={option.id}
        className={`flex items-center px-2 cursor h-40px mc-option-item`}
        onClick={() => filter?.client?.id !== option?.id && handleSelect(option?.id ? option : null)}>
        <label className="regular-text flex client-name">{option.name}</label>
      </div>
      {option?.id && filter?.client?.id === option?.id && (
        <OverviewBeneficiaryFilter key={`beneficiary-${option.id}`} filter={filter} setFilter={setFilter} />
      )}
    </div>
  );
};

const OverviewFilter = ({ categoryTag, filter, setFilter }) => {
  const dispatch = useDispatch();
  const userOrganization = getItemFromLocalStorage('user')?.organizations?.[0];
  const organization_id = userOrganization?.id;

  const { height } = useWindowSize();

  const [clientData, setClientData] = useState({});
  const [isScroll, setIsScroll] = useState(false);

  const fetchClientList = (page = 0, merge = false) => {
    dispatch(getOrganizationNetworks({ organization_id, params: { page: page, category_tag: 'CLIENT' } }))
      .then(res => {
        const clientContent = merge
          ? [...clientData?.content, ...res?.content]
          : [{ name: 'None', id: null }, ...res?.content];
        setClientData({ ...res, content: clientContent });
      })
      .catch(err => {
        setClientData({});
      });
  };

  const fetchMoreClients = () => {
    fetchClientList(clientData?.number + 1, true);
  };

  useEffect(() => {
    if (categoryTag === 'SAAS_OPERATOR') {
      fetchClientList();
    }
  }, []);

  const handleScroll = () => {
    const clientOptionContainer = document.getElementById('client-option-container');
    setIsScroll(clientOptionContainer?.clientHeight < height - 90 ? false : true);
  };

  useEffect(() => {
    setTimeout(() => handleScroll(), 1000);
  }, [height]);

  if (categoryTag === 'CLIENT') {
    return (
      <OverviewFilterWrapper>
        <OverviewBeneficiaryFilter
          isMulti={false}
          isClientCategory={true}
          filter={{ ...filter, client: userOrganization }}
          setFilter={setFilter}
        />
      </OverviewFilterWrapper>
    );
  }

  return (
    <OverviewFilterWrapper className={classNames(isScroll && 'scroll-client')}>
      <DropDown
        menuHeight={'auto'}
        className="client-dropdown"
        options={clientData?.content || []}
        isPaged={true}
        key={'client'}
        optionContainerId="client-option-container"
        optionContainerClassname="client-options"
        pageNumber={clientData?.number || 0}
        fetchData={fetchMoreClients}
        setSelected={option => setFilter({ client: option })}
        hasMore={!clientData?.last || false}
        selected={filter?.client || null}
        placeholder="Select Client"
        closeOnSelect={false}
        hideOptions={true}
        // this is crucial to have useCallback to avoid re-mounting of the component
        RenderOption={useCallback(
          ({ option, handleSelect }) => (
            <RenderClientOption
              key={`client-${option.id}`}
              option={option}
              handleSelect={handleSelect}
              filter={filter}
              setFilter={setFilter}
            />
          ),
          [filter?.client?.id],
        )}
      />
    </OverviewFilterWrapper>
  );
};

export const OverviewFilterWrapper = styled.div`
  width: 264px;

  .client-dropdown {
    .client-options {
      max-height: calc(100dvh - 80px);
      overflow-y: visible;
    }
    .selected-client {
      background-color: ${({ theme }) => theme.colors.backgroundColor};

      .mc-option-item {
        .client-name {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
    }
  }

  &.scroll-client {
    .client-dropdown {
      .client-options {
        max-height: calc(100dvh - 80px);
        overflow-y: scroll;
      }
    }
  }

  .beneficiary-dropdown {
    background-color: white;
    .organisations-border {
      height: unset;
      min-height: 40px;
    }

    .mc-options {
      top: calc(100% + 4px);
    }

    .selected-beneficiary-option {
      background-color: ${({ theme }) => theme.colors.ZenLightGray};
    }
  }
`;

export default OverviewFilter;
