import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import { capitalize, formatText } from '../../helpers/utils';
import { PlayerListItemsWrapper } from '../../styles/components/playerlist/playerlist-styled';
import InfiniteScrollV2 from '../common/infinite-scroll-v2';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import Status from '../common/status';

const renderSortableHeader = (label, showBorderRight = true) => {
  return (
    <div className={`flex w-full items-center px-2 overflow-hidden py-11px ${showBorderRight && 'border-right'}`}>
      <label className="flex cursor medium-text font-12 lighter-text gap-6 flex-1 justify-center text-center">
        {label}
      </label>
    </div>
  );
};

const PlayerListItems = ({ search = '', expanded, playerList, fetchMoreData = () => {} }) => {
  const userOrganization = getItemFromLocalStorage('user')?.organizations?.[0];
  const categoryTag = userOrganization?.category?.tag?.tag;

  const { t } = useTranslation();

  const renderClientName = player => {
    const { lotteries } = player || {};
    const client = lotteries?.[0]?.client;

    return (
      <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
        <label className="regular-text font-14 one-line">{client?.name}</label>
      </div>
    );
  };

  const renderBeneficiaryName = player => {
    const { lotteries } = player || {};
    const beneficiary = lotteries?.[0]?.beneficiary;

    return (
      <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
        <label className="regular-text font-14 one-line">{beneficiary?.name}</label>
      </div>
    );
  };

  const renderEntries = player => {
    const { lotteries } = player || {};
    const entries = lotteries?.[0]?.entries;

    return (
      <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
        <label className="regular-text font-14 one-line">{entries}</label>
      </div>
    );
  };

  const renderPlayerNumber = player => {
    const { lotteries } = player || {};
    const player_number = lotteries?.[0]?.player_number;

    return (
      <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
        <label className="regular-text font-14 one-line">{player_number}</label>
      </div>
    );
  };

  return (
    <PlayerListItemsWrapper className={`flex-column flex-1 divider-border radius-15px overflow-scroll `}>
      <div className={`flex-1 flex-column overflow-hidden ${expanded ? 'expand-list-player' : 'table-without-expand'}`}>
        <div
          className={`w-full content-header radius-top-left-15px radius-top-right-15px h-40px thead-sticky ${
            categoryTag === 'SAAS_OPERATOR'
              ? 'content-s-grid '
              : categoryTag === 'CLIENT'
                ? 'content-client-grid '
                : categoryTag === 'BENEFICIARY'
                  ? 'content-beneficiary-grid '
                  : ' '
          } `}>
          {renderSortableHeader(t('PLAYER_NUMBER'))}
          {categoryTag === 'SAAS_OPERATOR' && renderSortableHeader(t('CLIENT'))}
          {categoryTag !== 'BENEFICIARY' && renderSortableHeader(t('BENIFICIARY'))}
          {renderSortableHeader(t('PLAYER_NAME'))}
          {renderSortableHeader(t('POSTCODE'))}
          {renderSortableHeader(t('FIRST_ENTERED'))}
          {renderSortableHeader(t('NO_OF_ENTRIES'))}
          {renderSortableHeader(t('PLAYER_STATUS'))}
          <div></div>
        </div>
        {playerList?.content?.length > 0 ? (
          <div className="flex flex-1 overflow-hidden" id="scrollableDiv">
            <InfiniteScrollV2
              hasMore={!playerList?.last}
              fetchMoreData={fetchMoreData}
              dataLength={playerList?.content?.length}>
              {playerList?.content?.map(item => (
                <div
                  className={`w-full content-body border-bottom cursor ${
                    categoryTag === 'SAAS_OPERATOR'
                      ? 'content-s-grid '
                      : categoryTag === 'CLIENT'
                        ? 'content-client-grid'
                        : categoryTag === 'BENEFICIARY'
                          ? 'content-beneficiary-grid'
                          : 'content-beneficiary-grid'
                  } `}
                  key={item.id}>
                  {renderPlayerNumber(item)}
                  {categoryTag === 'SAAS_OPERATOR' && renderClientName(item)}
                  {categoryTag !== 'BENEFICIARY' && renderBeneficiaryName(item)}
                  <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
                    <label className="regular-text font-14 one-line">{item?.name}</label>
                  </div>
                  <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
                    <label className="regular-text font-14">{item?.post_code}</label>
                  </div>
                  <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo">
                    <label className="regular-text font-14 one-line">
                      {item?.entered_on ? moment.unix(item.entered_on).format('DD/MM/YYYY') : '-'}
                    </label>
                  </div>
                  {renderEntries(item)}
                  <div className="flex justify-center items-center w-full px-6 border-right py-15px space-respo w-full">
                    <label className="regular-text font-14">
                      <Status
                        withDot
                        withDottedBorder={false}
                        status={item?.status}
                        statusText={capitalize(formatText(item?.status))}
                      />
                    </label>
                  </div>
                </div>
              ))}
            </InfiniteScrollV2>
          </div>
        ) : search ? (
          <NoResultComponent />
        ) : (
          <NoDataComponent />
        )}
      </div>
    </PlayerListItemsWrapper>
  );
};

export default PlayerListItems;
