import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getFormattedNumber } from '../../helpers/utils';
import SplineChart from '../Charts/SplineChart';

const ProjectProfit = () => {
  const { profitProjectStats } = useSelector(state => state?.overview);

  const { t } = useTranslation();

  const seriesData = [
    profitProjectStats?.week,
    profitProjectStats?.month,
    profitProjectStats?.quarter,
    profitProjectStats?.year,
  ];
  const xaxisData = ['This week', 'Monthly', 'Quarterly ', 'Annual'];

  return (
    <div
      className={`border radius-4 pxy-4 flex-column flex-1 h-full project-profit-respo`}
      style={{ height: 300, width: 544 }}>
      <div className="flex justify-between">
        <label className="font-16 medium-text mb-4">{t('PROJECTED_PROFIT')}</label>
      </div>
      <div className="line-chart-over">
        <SplineChart
          tooltipValueFormatter={value => getFormattedNumber(value || 0)}
          seriesData={seriesData}
          xaxisData={xaxisData}
          dashed={true}
          Tootltiptile={'Projected Profit'}
          formate={true}
          ysplitLine={true}
        />
      </div>
    </div>
  );
};

export default ProjectProfit;
