import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowLeftWhiteIcon } from '../../assets/images/arrow-left-white.svg';
import { ReactComponent as ArrowRightWhiteIcon } from '../../assets/images/arrow-right-white.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/arrow-right.svg';
import { ReactComponent as DominoMask } from '../../assets/images/domino-mask.svg';
import zentNameLogo from '../../assets/images/logo-zen-text.svg';
import { ReactComponent as LogoutIcon } from '../../assets/images/sidebar/logout.svg';
import { ReactComponent as UserActionIcon } from '../../assets/images/sidebar/user-action-icon.svg';
import { ReactComponent as UserIcon } from '../../assets/images/sidebar/user.svg';
import Logo from '../../assets/images/zenterprize-logo.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getUserInitials } from '../../helpers/utils';
import { setUserLogout } from '../../store/features/userSlice';
import { SidebarWrapper } from '../../styles/components/sidebar/sidebar.styled';
import MenuItem from './menu-item';

const Sidebar = ({ menuItems, onItemClick, selectedPath, expanded, setExpanded }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { hasPermission } = useContext(OrganisationContext);
  const { user } = useSelector(state => state.user);

  const { applicationData, applicationDataLoading } = useSelector(state => state.application);
  const { images } = applicationData || {};
  const { LOGO, SMALL_LOGO } = images || {};

  const { user: userDetail } = user || {};

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMasqueradeDetails, setShowMasqueradeDetails] = useState(false);

  const logout = () => {
    localStorage.removeItem('user');
    dispatch(setUserLogout());
    navigate('/login', { replace: true });
  };

  const goToProfile = () => {
    navigate('/profile');
    setShowUserMenu(false);
  };

  const goToHome = () => {
    navigate('/home/logs');
  };

  const [userMenuItems, setUserMenuItems] = useState([
    {
      id: 0,
      text: 'My Profile',
      Icon: UserIcon,
      IconGreen: UserIcon,
      path: '/profile',
      action: goToProfile,
    },
    {
      id: 1,
      text: 'Log Out',
      Icon: LogoutIcon,
      IconGreen: LogoutIcon,
      action: logout,
    },
  ]);

  useEffect(() => {
    setUserMenuItems(userMenuItems.map(item => ({ ...item, selected: selectedPath.includes(item.path) })));
  }, [selectedPath]);

  useEffect(() => {
    if (user?.isAccessAccount) {
      setExpanded(true);
    }
  }, [user?.isAccessAccount]);

  return (
    <SidebarWrapper
      className={`${expanded ? 'expanded' : 'collapsed'}`}
      style={{
        transition: 'all 400ms linear',
      }}>
      <div className="flex items-center pxy-6">
        {applicationDataLoading ? null : !expanded ? (
          <img
            src={SMALL_LOGO ? SMALL_LOGO?.thumbnail_url : LOGO ? LOGO?.thumbnail_url : Logo}
            alt="zen-logo"
            width={30}
            height={30}
            className="mr-2"
            onClick={() => goToHome()}
          />
        ) : (
          <img src={LOGO ? LOGO?.url : zentNameLogo} alt="zen-logo" height={40} onClick={() => goToHome()} />
        )}
      </div>
      {!user.isAccessAccount && (
        <div
          className={`absolute flex items-center justify-center cursor expand-icon`}
          style={{
            transition: 'all 400ms linear',
          }}
          onClick={() => setExpanded(!expanded)}>
          {expanded ? <ArrowLeftWhiteIcon /> : <ArrowRightWhiteIcon />}
        </div>
      )}
      <div className="sidebar-top" tabIndex="0">
        <div className="flex items-center justify-between cursor user-info no-select relative" tabIndex={0}>
          <div className="w-full menu-items">
            {user.isAccessAccount && expanded && (
              <div className="flex items-center w-full px-6 py-3 relative access-account-sidebar">
                <div
                  className="flex items-center w-full"
                  onClick={() => setShowMasqueradeDetails(!showMasqueradeDetails)}>
                  <div className="flex-column flex-1">
                    <label className="regular-text grey-text font-12">ACCOUNT</label>
                    <label className="medium-text zen-purple-text">{user.selectedAccount?.name}</label>
                  </div>
                  <div className="px-2 py-1">
                    <ArrowRight className={showUserMenu ? 'rotate-270' : 'rotate-90'} />
                  </div>
                </div>
              </div>
            )}
            {menuItems.map((item, i) => (
              <MenuItem key={i} item={item} onClick={onItemClick} expanded={expanded} />
            ))}
          </div>
        </div>
      </div>
      <div
        className="sidebar-bottom"
        onBlur={e => {
          e.preventDefault();
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setShowUserMenu(false);
          }
        }}
        onClick={() => setShowUserMenu(!showUserMenu)}
        tabIndex="0">
        <div className="flex items-center py-3 px-6 user-name cursor">
          {user.isAccessAccount ? (
            <div className="flex pxy-1 radius-2 mr-2 masquerade-user-icon">
              <DominoMask />
            </div>
          ) : (
            <div className="userProfile-box mr-2">
              {userDetail?.avatar_url ? (
                <img src={userDetail?.avatar_url} alt="profile" className="profile-icon mr-2" />
              ) : (
                <div
                  className="user-icon flex items-center justify-center empty-img nots-user-image radius-2"
                  style={{ width: '32px', height: '32px' }}>
                  <span className="font-14 bold-text grey-text">{getUserInitials(userDetail)}</span>
                </div>
              )}
            </div>
          )}
          {expanded && (
            <>
              <label className="semibold-text white-text flex-1 one-line">
                {' '}
                {`${userDetail?.forename} ${userDetail?.surname}`}
              </label>
              <UserActionIcon className="ml-2" />
            </>
          )}
        </div>
        <div className={`user-menu ${showUserMenu ? 'show-menu' : 'hidden-menu'}`}>
          {userMenuItems.map((item, i) => (
            <div
              key={i}
              className={`user-action ${item.selected && 'user-action-selected'} ${
                !item.permission || (hasPermission(item.permission) ? 'visible' : 'hidden')
              }`}
              onClick={() => {
                item.action();
              }}>
              <div className={`flex items-center py-4 px-6 cursor ${expanded ? 'px-6' : 'px-2 justify-center'}`}>
                <item.Icon className="mr-3 user-action-icon" />
                {expanded && <label className="regular-text font-12">{item.text}</label>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;
