import moment from 'moment';

export const initModal = {
  type: null,
  content: {},
};

export const dateRangeOption = [
  {
    name: 'Today',
    label: 'Today',
    value: 'TODAY',
    startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().set('hour', 24).set('minute', 0).set('second', 0).unix(),
  },
  {
    name: 'Current Month',
    label: 'Current Month',
    value: 'CURRENT_MONTH',
    startDate: moment().startOf('month').set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().endOf('month').set('hour', 23).set('minute', 59).set('second', 59).unix(),
  },
  {
    name: 'Last 7 days',
    label: 'Last 7 days',
    value: 'LAST_7_DAYS',
    startDate: moment().subtract(7, 'd').set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().unix(),
  },
  {
    name: 'Last month',
    label: 'Last month',
    value: 'LAST_MONTH',
    startDate: moment().add(-1, 'months').startOf('month').set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().add(-1, 'months').endOf('month').set('hour', 23).set('minute', 59).set('second', 59).unix(),
  },
  {
    name: 'All',
    label: 'All',
    value: 'ALL',
    startDate: moment().set('hour', 0).set('minute', 0).set('second', 0).unix(),
    endDate: moment().unix(),
  },
  {
    name: 'Custom',
    label: 'Custom',
    value: 'CUSTOM',
    startDate: moment().add(-1, 'months').unix(),
    endDate: moment().unix(),
  },
];
