import Axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as BackIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as LoginErrorIcon } from '../../assets/images/error.svg';
import Logo from '../../assets/images/logo.svg';
import { ReactComponent as RefreshIcon } from '../../assets/images/refresh-icon.svg';
import TextLogo from '../../assets/images/zenterprize.svg';
import Button from '../../components/Elements/button/button';
import { removeItemFromLocalStorage, setItemInLocalStorage } from '../../helpers/localstorage';
import { formatText } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import OtpInput from '../otp-input';

const MultiFactorAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { applicationData, applicationDataLoading } = useSelector(state => state.application);
  const { images } = applicationData || {};
  const { AUTH_LOGO } = images || {};

  const [defaultMFAOption, setDefaultMFAOption] = useState({});
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [isFirstInputFocus, setFirstInputFocus] = useState(false);

  const { user } = useSelector(state => state.user);

  const goToLogin = () => {
    removeItemFromLocalStorage('user');
    navigate('/login');
    dispatch(setUser(null));
  };

  const getMultiFactorToken = () => {
    const multifactorToken = user.multifactor_token;
    const api_tokens = user.api_tokens;
    if (api_tokens) {
      navigate('/*');
      return;
    }
    if (!multifactorToken) {
      goToLogin();
      return;
    }
    if (multifactorToken.expiration < moment().valueOf()) {
      goToLogin();
      return;
    }
    return `Bearer ${multifactorToken.access_token}`;
  };

  const sendOtp = async option => {
    const token = getMultiFactorToken();
    if (token) {
      try {
        await Axios.post(
          `${process.env.REACT_APP_API_URL}/authentication/otp`,
          {
            method: option.type,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );
      } catch ({ response }) {
        const { data } = response || {};
        if (data?.errorCode === 'FORBIDDEN') {
          goToLogin();
        }
      }
    }
  };

  useEffect(() => {
    const defaultMFAOption = user.multifactor_options?.find(mfa => mfa.is_default) || {};
    setDefaultMFAOption(defaultMFAOption);
    sendOtp(defaultMFAOption);
  }, [user?.multifactor_token]);

  const changeMFAOption = () => {
    const mfsOption = user.multifactor_options?.find(option => defaultMFAOption.type !== option.type) || {};
    setDefaultMFAOption(mfsOption);
    sendOtp(mfsOption);
  };

  const verifyOtp = async () => {
    if (otpValue && otpValue.length === 6) {
      const token = getMultiFactorToken();
      if (token) {
        try {
          const { data: api_tokens } = await Axios.post(
            `${process.env.REACT_APP_API_URL}/authentication/otp/validate`,
            {
              method: defaultMFAOption.type,
              code: otpValue,
            },
            {
              headers: {
                Authorization: token,
              },
            },
          );
          const updatedUser = {
            ...user,
            api_tokens: { ...api_tokens },
            multifactor_token: null,
            multifactor_options: null,
          };
          setItemInLocalStorage('user', updatedUser);
          navigate('/*');
          dispatch(setUser(updatedUser));
        } catch ({ response }) {
          const { data } = response || {};
          if (data?.errorCode === 'FORBIDDEN') {
            goToLogin();
          }
          if (data?.error_code === 'UNAUTHORIZED') {
            setIsOtpValid(false);
            dispatch(
              addToast({ error: true, text: 'Incorrect code - please check your code is correct and try again' }),
            );
          }
          if (data?.error_code === 'BAD_REQUEST') {
            setIsOtpValid(false);
            dispatch(
              addToast({ error: true, text: 'Incorrect code - please check your code is correct and try again' }),
            );
          }
        }
      }
    }
  };

  const resendOtp = () => {
    setIsOtpValid(true);
    sendOtp(defaultMFAOption);
  };

  useEffect(() => {
    setIsOtpValid(true);
  }, [otpValue, defaultMFAOption]);

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        verifyOtp();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [otpValue]);

  return (
    <MultiFactorAuthWrapper>
      <div className="mfa-page">
        <div className="mfa relative">
          <div className="flex items-center justify-center cursor back-icon-container absolute" onClick={goToLogin}>
            <BackIcon className="back-icon" height={16} width={16} />
          </div>
          <div className="mfa-form card pxy-12 ">
            {applicationDataLoading ? null : AUTH_LOGO ? (
              <img className="custom-logo" src={AUTH_LOGO?.url} alt="logo" />
            ) : (
              <>
                <img className="logo" src={Logo} alt="logo" />
                <img className="text-logo" src={TextLogo} alt="text-logo" />
              </>
            )}
            <>
              <label className="bold-text font-20 mt-6">
                {defaultMFAOption.type === 'EMAIL' ? t('WE_SENT_YOU_EMAIL') : t('WE_SENT_YOU_SMS')}
              </label>
              <label className="subtitle regular-text grey-text font-12 mt-2">
                {t('SECURITY_REASON_DETAIL_TEXT', { type: formatText(defaultMFAOption.type) })}
              </label>
              <OtpInput
                className={'otp-input'}
                containerClass={'otp-content'}
                errorStyle={'error-style'}
                hasErrored={!isOtpValid}
                inputStyle={'input-style'}
                isInputNum={true}
                onChange={data => {
                  setFirstInputFocus(false);
                  setOtpValue(data);
                }}
                placeholder="000000"
                value={otpValue}
                isFirstInputFocus={isFirstInputFocus}
              />
              {!isOtpValid && (
                <div className="flex otp-error pxy-1 mt-4">
                  <LoginErrorIcon className="error-icon mr-1" />
                  <label className="medium-text font-12 white-text ml-2">{t('INCORRECT_OTP_CODE_ERROR')}</label>
                </div>
              )}
              <Button
                size="large"
                width="100%"
                className={`mt-10 ${otpValue && otpValue.length === 6 ? 'primary' : 'disabled'}`}
                onClick={verifyOtp}
                label={t('VERIFY')}
                borderRadius="16px"
              />
              <div
                className="flex items-center cursor re-send mt-6 cursor"
                onClick={() => {
                  resendOtp();
                  setFirstInputFocus(true);
                }}>
                <RefreshIcon className="re-send-icon" height={16} width={16} />
                <label className="medium-text font-12 ml-1 color-purple">{t('RESEND_SECURE_CODE')}</label>
              </div>
              {user?.multifactor_options?.length > 1 && (
                <Button
                  size="medium"
                  width="55%"
                  className="mt-6 primary-white"
                  borderRadius="12px"
                  onClick={() => {
                    setFirstInputFocus(true);
                    changeMFAOption();
                  }}
                  label={defaultMFAOption.type === 'EMAIL' ? t('SEND_CODE_BY_SMS') : t('SEND_CODE_BY_EMAIL')}
                />
              )}
            </>
          </div>
        </div>
      </div>
    </MultiFactorAuthWrapper>
  );
};

const MultiFactorAuthWrapper = styled.div`
  .mfa-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: ${props => props.theme.colors.color};
    background-color: ${props => props.theme.colors.backgroundColor};

    .mfa {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      justify-content: center;

      .custom-logo {
        height: 128px;
      }

      .back-icon-container {
        top: 23%;
        left: 100px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(103, 103, 103, 0.08);
        border-radius: 16px;
        width: 40px;
        height: 40px;

        .back-icon {
          path {
            stroke: ${({ theme }) => theme.colors.zenPurple};
          }
        }
      }

      .mfa-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
          height: 80px;
        }

        .text-logo {
          height: 48px;
        }

        .subtitle {
          max-width: 360px;
          text-align: center;
        }

        .otp-content {
          margin-top: 40px;

          .otp-input {
            margin: 0px 4px;

            .input-style {
              width: 50px;
              height: 50px;
              border: unset;
              text-align: center;
              background: #ffffff;
              // box-shadow: 5px 5px 30px rgb(5 49 73 / 10%);
              font-size: 16px;
              color: #053149;
              font-family: Poppins Regular;
              border: 1px solid #d0d5dd;
              border-radius: 12px;

              &:focus-visible {
                outline: 0px;
                border: 1px solid ${({ theme }) => theme.colors.zenPurple};
              }

              &::-webkit-input-placeholder {
                opacity: 0.3;
                color: #053149;
              }
            }

            .error-style {
              border: 1px solid #ff5b5b;

              &:focus-visible {
                border: 1px solid #ff5b5b;
              }
            }
          }
        }

        .re-send-icon {
          path {
            fill: rgb(137, 39, 239);
          }
        }

        .otp-error {
          width: 340px;
          background: #ff406e;
          border-radius: 8px;
          padding: 4px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .error-msg {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          top: 50px;

          .error-icon {
            min-width: 12px;
            min-height: 12px;
            width: 12px;
            height: 12px;
            margin: 0px;
          }

          label {
            font-family: Poppins Medium;
            font-style: normal;
            font-size: 12px;
            color: #d93e38;
            margin-left: 5px;
          }
        }

        .error-message {
          display: flex;
          align-items: center;
          background: #ff406e;
          border-radius: 8px;
          padding: 4px 8px;

          .error-icon {
            path {
              stroke: #ffffff;
            }
          }
        }
      }
    }
  }
`;

export default MultiFactorAuth;
