import Axios from 'axios';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import styled from 'styled-components';
import Logo from '../../assets/images/logo.svg';
import TextLogo from '../../assets/images/zenterprize.svg';
import CheckboxRounded from '../../components/CheckboxRounded';
import Button from '../../components/Elements/button/button';
import { removeItemFromLocalStorage } from '../../helpers/localstorage';
import { formatText } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import OtpInput from '../otp-input';

const MultiFactorSetup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.user);
  const { applicationData, applicationDataLoading } = useSelector(state => state.application);

  const { user: userDetails } = user || {};
  const { images } = applicationData || {};
  const { AUTH_LOGO } = images || {};

  const [defaultMFAOption, setDefaultMFAOption] = useState({});
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [isOtpSending, setIsOtpSending] = useState(false);
  const [isOtpVerifying, setIsOtpVerifying] = useState(false);

  const goToLogin = () => {
    removeItemFromLocalStorage('user');
    navigate('/login');
    dispatch(setUser(null));
  };

  const getMultiFactorToken = () => {
    const multifactorToken = user.multifactor_token;
    const api_tokens = user.api_tokens;
    if (api_tokens) {
      navigate('/overview');
      return;
    }
    if (!multifactorToken) {
      goToLogin();
      return;
    }
    if (multifactorToken.expiration < moment().valueOf()) {
      dispatch(addToast({ error: true, text: 'Your session has expired. Please login again.', id: uuid() }));
      goToLogin();
      return;
    }
    return `Bearer ${multifactorToken.access_token}`;
  };

  const sendOtp = async (key, value) => {
    const token = getMultiFactorToken();
    if (token) {
      try {
        setIsOtpSending(true);
        const { data } = await Axios.post(
          `${process.env.REACT_APP_API_URL}/users/multifactor`,
          {
            type: key,
            is_default: true,
          },
          {
            headers: {
              Authorization: token,
            },
          },
        );
        setDefaultMFAOption({ ...defaultMFAOption, multifactorData: data });
        setIsOtpSending(false);
        dispatch(addToast({ text: 'Code sent successfully', id: uuid() }));
      } catch ({ response }) {
        const { data } = response || {};
        setIsOtpSending(false);
        dispatch(addToast({ error: true, text: data?.error_description || 'Error while sending code', id: uuid() }));
      }
    }
  };

  const verifyOtp = async () => {
    const { multifactorData } = defaultMFAOption || {};
    if (otpValue && otpValue.length === 6 && multifactorData?.id) {
      const token = getMultiFactorToken();
      if (token) {
        try {
          setIsOtpVerifying(true);
          const { data } = await Axios.post(
            `${process.env.REACT_APP_API_URL}/users/multifactor/${multifactorData?.id}/confirm`,
            {
              method: defaultMFAOption.type,
              code: otpValue,
            },
            {
              headers: {
                Authorization: token,
              },
            },
          );
          setIsOtpVerifying(false);
          dispatch(addToast({ text: 'MFA configuration successful', id: uuid() }));
          localStorage.removeItem('user');
          navigate('/login', { replace: true });
        } catch ({ response }) {
          const { data } = response || {};
          if (data?.error_code === 'UNAUTHORIZED') {
            setIsOtpValid(false);
          }
          setIsOtpVerifying(false);
          dispatch(
            addToast({ error: true, text: data?.error_description || 'Error while verifying code', id: uuid() }),
          );
        }
      }
    }
  };

  useEffect(() => {
    const { email, phone } = userDetails || {};
    if (email && !phone) {
      setDefaultMFAOption({ type: 'EMAIL' });
    }
    if (!email && phone) {
      setDefaultMFAOption({ type: 'SMS' });
    }
  }, [userDetails]);

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        verifyOtp();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [otpValue]);

  const renderInput = (key, label, value) => {
    return (
      <div className="flex items-start w-full mt-6">
        <div className="mt-1">
          <CheckboxRounded
            onChange={() => setDefaultMFAOption({ type: key })}
            checked={key === defaultMFAOption.type}
          />
        </div>
        <div className="flex-column ml-2 w-full">
          <div className="flex items-center">
            <label className="regular-text font-16 ml-2">{label}</label>
            {key !== defaultMFAOption.type && (
              <label className="regular-text grey-text ml-2">{value || 'no data'}</label>
            )}
          </div>
          {key === defaultMFAOption.type && (
            <Fragment>
              <input
                className="input input-container mt-4 w-full"
                placeholder={`Enter ${label}`}
                value={value}
                disabled={true}
              />
              <Button
                size="medium"
                label="Send code"
                className="primary mt-4 radius-3"
                disabled={!value || isOtpSending}
                onClick={() => sendOtp(key, value)}
              />
              <label className="regular-text grey-text mt-6 mb-2">
                Verify the code sent to your {formatText(label)}
              </label>
              <OtpInput
                className="otp-input"
                containerClass="otp-content"
                errorStyle="error-style"
                hasErrored={!isOtpValid}
                inputStyle="input font-12 text-center radius-2 input-style"
                isInputNum={true}
                onChange={setOtpValue}
                placeholder="000000"
                value={otpValue}
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  };

  return (
    <MultiFactorSetupWrapper>
      <div className="mfa-page">
        <div className="mfa">
          <div className="mfa-form card">
            {applicationDataLoading ? null : AUTH_LOGO ? (
              <img src={AUTH_LOGO?.url} alt="logo" className="custom-logo" />
            ) : (
              <>
                <img className="logo" src={Logo} alt="logo" />
                <img className="text-logo" src={TextLogo} alt="text-logo" />
              </>
            )}
            <Fragment>
              <label className="bold-text font-20 mt-6">MFA configuration</label>
              <label className="subtitle regular-text grey-text font-12 mt-2">
                Select a convenient method for authentication
              </label>
              {userDetails.email && renderInput('EMAIL', 'Email', userDetails.email)}
              {userDetails.phone && renderInput('SMS', 'Mobile', userDetails.phone)}
              <Button
                size="large"
                width="100%"
                className={'mt-10 primary'}
                disabled={
                  isOtpVerifying || !(otpValue && otpValue.length === 6 && defaultMFAOption.multifactorData?.id)
                }
                onClick={verifyOtp}
                label={'Set'}
                borderRadius="16px"
              />
            </Fragment>
          </div>
        </div>
      </div>
    </MultiFactorSetupWrapper>
  );
};

const MultiFactorSetupWrapper = styled.div`
  .mfa-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: ${props => props.theme.colors.color};
    background-color: ${props => props.theme.colors.backgroundColor};

    .mfa {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      justify-content: center;

      .custom-logo {
        height: 128px;
      }

      .mfa-form {
        width: 488px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 56px 64px 64px 64px;

        .logo {
          width: 152px;
          height: 80px;
        }

        .input-container {
          height: 40px;
        }

        .otp-content {
          .otp-input {
            margin: 0px 4px;

            .input-style {
              width: 32px;
              height: 32px;
              padding: 0px;
            }

            .error-style {
              border: 1px solid #ff5b5b;

              &:focus-visible {
                border: 1px solid #ff5b5b;
              }
            }
          }
        }
      }
    }
  }
`;

export default MultiFactorSetup;
