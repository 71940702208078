import styled from 'styled-components';

export const PlayerListItemsWrapper = styled.div`
  .thead-sticky {
    position: sticky;
    top: 0;
  }
  .content-s-grid {
    display: grid;
    grid-template-columns:
      minmax(136px, 0.5fr) minmax(188px, 0.5fr) minmax(144px, 0.5fr) minmax(144px, 0.5fr) minmax(128px, 0.5fr)
      minmax(128px, 0.5fr) minmax(112px, 0.5fr) minmax(144px, 0.5fr);
  }
  .content-client-grid {
    display: grid;
    grid-template-columns:
      minmax(136px, 0.5fr) minmax(192px, 0.5fr) minmax(168px, 0.5fr) minmax(136px, 0.5fr) minmax(160px, 0.5fr)
      minmax(144px, 0.5fr) minmax(167px, 0.5fr);
  }
  .content-beneficiary-grid {
    display: grid;
    grid-template-columns:
      minmax(224px, 0.5fr) minmax(224px, 0.5fr) minmax(160px, 0.5fr) minmax(184px, 0.5fr) minmax(144px, 0.5fr)
      minmax(167px, 0.5fr);
  }
  .content-header {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .content-body {
    min-height: 48px;
    &:hover {
      background: ${({ theme }) => theme.colors.hoverColor};
    }
  }

  @media (max-width: 1200px) {
    .expand-list-player .content-s-grid {
      grid-template-columns:
        minmax(120px, 120px) minmax(115px, 115px) minmax(115px, 115px) minmax(115px, 115px)
        minmax(100px, 100px) minmax(100px, 100px) minmax(95px, 95px) minmax(102px, 102px);
    }

    .table-without-expand .content-s-grid {
      grid-template-columns:
        minmax(136px, 136px) minmax(150px, 150px) minmax(144px, 144px) minmax(144px, 144px)
        minmax(107px, 107px) minmax(120px, 120px) minmax(112px, 112px) minmax(125px, 125px);
    }
    .expand-list-player .content-beneficiary-grid {
      grid-template-columns:
        minmax(162px, 0.5fr) minmax(140px, 0.5fr) minmax(150px, 0.5fr)
        minmax(150px, 0.5fr) minmax(120px, 0.5fr) minmax(140px, 0.5fr);
    }

    .table-without-expand .content-beneficiary-grid {
      grid-template-columns:
        minmax(210px, 0.5fr) minmax(210px, 0.5fr) minmax(150px, 0.5fr) minmax(170px, 0.5fr)
        minmax(130px, 0.5fr) minmax(168px, 0.5fr);
    }
    .expand-list-player .content-client-grid {
      grid-template-columns:
        minmax(130px, 130px) minmax(150px, 0.5fr) minmax(145px, 0.5fr) minmax(115px, 0.5fr)
        minmax(110px, 0.5fr) minmax(100px, 0.5fr) minmax(112px, 0.5fr);
    }
    .table-without-expand .content-client-grid {
      grid-template-columns:
        minmax(136px, 0.5fr) minmax(168px, 0.5fr) minmax(160px, 0.5fr)
        minmax(136px, 0.5fr) minmax(136px, 0.5fr) minmax(135px, 0.5fr) minmax(167px, 0.5fr);
    }
  }

  @media (max-width: 1024px) {
    .space-respo {
      padding: 15px 8px;
    }
    .table-without-expand .content-s-grid {
      grid-template-columns:
        minmax(120px, 120px) minmax(120px, 120px) minmax(120px, 120px)
        minmax(120px, 120px) minmax(107px, 107px) minmax(105px, 105px) minmax(100px, 100px) minmax(110px, 110px);
    }
    .expand-list-player .content-s-grid {
      grid-template-columns:
        minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr)
        minmax(90px, 0.5fr) minmax(90px, 0.5fr) minmax(90px, 0.5fr) minmax(90px, 0.5fr);
    }

    .expand-list-player .content-beneficiary-grid {
      grid-template-columns:
        minmax(140px, 0.5fr) minmax(140px, 0.5fr) minmax(125px, 0.5fr)
        minmax(125px, 0.5fr) minmax(112px, 0.5fr) minmax(120px, 0.5fr);
    }

    .table-without-expand .content-beneficiary-grid {
      grid-template-columns:
        minmax(160px, 0.5fr) minmax(160px, 0.5fr) minmax(150px, 0.5fr)
        minmax(150px, 0.5fr) minmax(130px, 0.5fr) minmax(152px, 0.5fr);
    }

    .expand-list-player .content-client-grid {
      grid-template-columns:
        minmax(110px, 130px) minmax(130px, 0.5fr) minmax(112px, 0.5fr)
        minmax(110px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr) minmax(100px, 0.5fr);
    }
    .table-without-expand .content-client-grid {
      grid-template-columns:
        minmax(136px, 0.5fr) minmax(168px, 0.5fr) minmax(150px, 0.5fr)
        minmax(125px, 0.5fr) minmax(130px, 0.5fr) minmax(120px, 0.5fr) minmax(150px, 0.5fr);
    }
  }
`;
