import React from 'react';
import { useSelector } from 'react-redux';
import { UsersListWrapper } from '../../styles/components/users-list/users-list.styled';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import UserListItem from './user-list-item';

const UsersList = ({ debouncedSearch, filter, updateUsersList }) => {
  const { users } = useSelector(state => state.usersManagement);

  return (
    <UsersListWrapper className="flex-column flex-1 w-full">
      {users?.content?.length > 0 ? (
        users?.content?.map(user => <UserListItem key={user?.id} user={user} updateUsersList={updateUsersList} />)
      ) : debouncedSearch || filter?.value?.id ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </UsersListWrapper>
  );
};

export default UsersList;
