import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as OverviewIcon } from '../../assets/images/sidebar/dashboard.svg';
import { ReactComponent as PrizeDrawsIcon } from '../../assets/images/sidebar/prize-draws.svg';
import { ReactComponent as SettingSelectedIcon } from '../../assets/images/sidebar/settings-selected.svg';
import { ReactComponent as SettingIcon } from '../../assets/images/sidebar/settings.svg';
import Sidebar from '../../components/sidebar/sidebar';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsersPermissions } from '../../helpers/utils';
import Modal from '../../popup';
import history from '../../routes/history';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/page/main.styled';
import { ROUTE_PATHS } from './route-paths';

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { user } = useSelector(state => state.user);

  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const [expanded, setExpanded] = useState(true);

  const permissions = getUsersPermissions();

  const hasPermission = permission => {
    return permissions.includes(permission);
  };

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    );
  };

  const items = useMemo(
    () => [
      {
        id: 1,
        icon: OverviewIcon,
        iconSelected: OverviewIcon,
        text: 'Overview',
        redirect: '/overview',
        selected: location.pathname === '/overview',
        subitems: [],
      },
      {
        id: 2,
        icon: PrizeDrawsIcon,
        iconSelected: PrizeDrawsIcon,
        text: 'Player List',
        redirect: '/player-list',
        selected: location.pathname === '/player-list',
        hasPermission: hasMultiplePermission(['PLAYERS_VIEW']),
      },
      {
        id: 3,
        icon: SettingIcon,
        iconSelected: SettingSelectedIcon,
        text: 'Settings',
        redirect: '/settings',
        selected: location.pathname === '/settings',
        subitems: [],
        setStrokeOfIcon: true,
        hasPermission: hasMultiplePermission(['SETTINGS_VIEW']),
      },
    ],
    [location.pathname],
  );

  const [menuItems, setMenuItems] = useState(
    items.map(item => ({ ...item, subitems: item.subitems ? item.subitems.filter(si => si.hasPermission) : [] })),
  );

  const onItemClick = (itemId, subitemId) => {
    const item = menuItems.find(item => item.id === itemId);
    if (item) {
      if (item.subitems.length > 0) {
        if (subitemId !== 0 && !subitemId)
          setMenuItems(
            menuItems.map(menuItem => (menuItem.id === itemId ? { ...menuItem, open: !menuItem.open } : menuItem)),
          );
        else {
          navigate(item.subitems.find(subitem => subitem.id === subitemId).redirect);
        }
      } else {
        navigate(item.redirect);
      }
    }
  };

  useEffect(() => {
    if (selectedPath)
      setMenuItems(
        menuItems.map(menuItem =>
          selectedPath.startsWith(menuItem.redirect)
            ? {
                ...menuItem,
                open: menuItem.subitems.length > 0,
                selected: true,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: selectedPath.startsWith(subitem.redirect),
                })),
              }
            : {
                ...menuItem,
                selected: false,
                open: false,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: false,
                })),
              },
        ),
      );
  }, [selectedPath]);

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
      setSelectedPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    navigator?.serviceWorker?.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList?.length > 0 && permissionList?.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  return (
    <MainWrapper>
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
          checkPermission,
          hasPermission,
          hasMultiplePermission,
          checkMultiplePermission,
        }}>
        <Sidebar
          menuItems={menuItems}
          onItemClick={onItemClick}
          selectedPath={selectedPath}
          user={user}
          setExpanded={setExpanded}
          expanded={expanded}
        />
        <div className="main">
          <div className="content">
            <Routes>
              {ROUTE_PATHS.filter(path => hasMultiplePermission(path.permission)).map(path => (
                <Route
                  key={path.id}
                  element={<path.Component {...path.params} path={path.path} {...path.actions} expanded={expanded} />}
                  path={path.path}
                />
              ))}
              <Route
                element={
                  <Navigate
                    replace
                    to={
                      ROUTE_PATHS.find(path => hasMultiplePermission(path.permission))
                        ? ROUTE_PATHS.find(path => hasMultiplePermission(path.permission)).path
                        : '/profile'
                    }
                  />
                }
                path="*"
              />
            </Routes>
          </div>
        </div>
        <Modal />
      </OrganisationContext.Provider>
    </MainWrapper>
  );
};

export default Main;
