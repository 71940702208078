import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uuid from 'react-uuid';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as LogoIcon } from '../../assets/images/zenterprize-logo-text.svg';
import Button from '../../components/Elements/button/button';
import PhoneInput from '../../components/common/phone-input';
import { Tooltip } from '../../components/tooltip/tooltip';
import { countMatchesInRegex, formatText, onlyNumbers } from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import { addToast } from '../../store/features/toastSlice';
import { activateUser, getPasswordRequirements, getRequiredField } from '../../store/features/userSlice';
import { AccountActivationWrapper } from '../../styles/invite/invite.styled';
import InviteExpire from '../Invite-expire';

const upperCaseRegex = /[A-Z]/;
const lowerCaseRegex = /[a-z]/g;
const numberCaseRegex = /[0-9]/;
const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

const AccountActivation = () => {
  const { applicationData, applicationDataLoading } = useSelector(state => state.application);
  const { images } = applicationData || {};
  const { AUTH_LOGO } = images || {};

  const [passwordRequirementError, setPasswordRequirementError] = useState({
    minumumPasswordLengthError: false,
    upperCaseError: false,
    lowerCaseError: false,
    numberError: false,
    specialCharacterError: false,
  });
  const [passwordRequiremets, setPasswordRequirements] = useState({
    min_length: 16,
    min_digits: 1,
    min_uppercase: 1,
    min_special: 1,
    min_lowercase: 1,
  });

  const query = useQuery();
  const cleanedToken = query.get('token');
  const tokenData = cleanedToken ? JSON.parse(window.atob(cleanedToken.split('.')[1])) : {};
  const isTokenExpired = moment().unix() > tokenData?.exp;

  const [passwordError, setPasswordError] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState();
  const [password, setPassword] = useState();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confimPassword, setConfirmPassword] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [requiredFields, setRequiredFields] = useState({});
  const [error, setError] = useState({});
  const [user, setUser] = useState({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchPasswordRequirements = async authenticationToken => {
    dispatch(getPasswordRequirements(authenticationToken))
      .then(data => setPasswordRequirements(data))
      .catch(e => setPasswordRequirements({}));
  };
  const checkPasswordRequirements = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    const minumumPasswordLengthError = password ? password?.length < min_length : true;
    const upperCaseError = min_uppercase > 0 ? countMatchesInRegex(upperCaseRegex, password) < min_uppercase : false;
    const lowerCaseError = min_lowercase > 0 ? countMatchesInRegex(lowerCaseRegex, password) < min_lowercase : false;
    const numberError = min_digits > 0 ? countMatchesInRegex(numberCaseRegex, password) < min_digits : false;
    const specialCharacterError =
      min_special > 0 ? countMatchesInRegex(specialCharacterRegex, password) < min_special : false;
    setPasswordRequirementError({
      minumumPasswordLengthError,
      upperCaseError,
      lowerCaseError,
      numberError,
      specialCharacterError,
    });
    return minumumPasswordLengthError || upperCaseError || lowerCaseError || numberError || specialCharacterError;
  };

  const passwordRequirementTooltip = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    return (
      <div className="flex">
        <ErrorIcon height={16} width={16} className="error-icon" />
        <div className="flex-column ml-2">
          <span className="medium-text grey-text font-12">{t('PASSWORD_MUST')}</span>

          {passwordRequirementError.minumumPasswordLengthError && (
            <span className="regular-text grey-text font-12">
              {t('HAVE_MIN_LENGTH_CHARACTERS', { min_length: min_length })}
            </span>
          )}
          {passwordRequirementError.upperCaseError && (
            <span className="regular-text grey-text font-12">
              {t('HAVE_MIN_UPPERCASE', { min_uppercase: min_uppercase })}
            </span>
          )}
          {passwordRequirementError.lowerCaseError && (
            <span className="regular-text grey-text font-12">
              {t('HAVE_MIN_LOWERCASE', { min_lowercase: min_lowercase })}
            </span>
          )}
          {passwordRequirementError.numberError && (
            <span className="regular-text grey-text font-12">{t('HAVE_MIN_NUMBER', { min_digits: min_digits })}</span>
          )}
          {passwordRequirementError.specialCharacterError && (
            <span className="regular-text grey-text font-12">
              {t('HAVE_MIN_SPECIAL_CHARACTER', { min_special: min_special })}
            </span>
          )}
        </div>
      </div>
    );
  };

  const checkErrors = () => {
    return {
      ...error,
      employeeId: requiredFields?.employeeid && !user?.employeeId?.trim() ? true : false,
      badgeNumber: requiredFields?.badgenumber && !user?.badgeNumber?.trim() ? true : false,
      phone: requiredFields?.phone && !user?.phone?.trim() ? true : false,
    };
  };

  const checkUserErrors = () => {
    const updatedError = checkErrors();
    if (Object.values(updatedError).some(err => err === true)) {
      setError({ ...error, ...updatedError });
      dispatch(addToast({ error: true, text: t('PLEASE_FILL_REQUIRED_FIELDS') }));
      return true;
    } else if (user?.phone?.trim() && user?.phone.length !== 12) {
      setError({ ...error, phone: true });
      dispatch(addToast({ error: true, text: t('PLEASE_ENTER_VALID_PHONE_NUMBER') }));
      setPhoneNumberError(t('PHONE_NUMBER_NOT_VALID'));

      return true;
    } else if (requiredFields?.badgenumber && user?.badgeNumber?.trim().length !== 8) {
      setError({ ...error, badgeNumber: true });
      dispatch(addToast({ error: true, text: t('BADGE_NUMBER_CHARACTER_LIMIT') }));
      return true;
    }
    return false;
  };

  const activate = async event => {
    if (checkUserErrors()) {
      return;
    }

    if (checkPasswordRequirements()) {
      setPasswordError(t('PASSWORD_NOT_MEET_MINIMUM_REQUIREMENT'));
      return;
    }

    if (password !== confimPassword) {
      setPasswordError(t('PASSWORD_NOT_MATCH'));
      return;
    }

    setPasswordError(false);
    setPhoneNumberError(false);
    setLoading(true);
    const request = {
      password,
      employee_number: requiredFields?.employeeid ? user?.employeeId : null,
      badge_number: requiredFields?.badgenumber ? user?.badgeNumber : null,
      country_code: user?.phone ? user?.phoneCountry || '+44' : '',
      phone: user?.phone ? `${user?.phone?.replaceAll('-', '').replaceAll(' ', '') || ''}` : '',
      avatar: {
        id: requiredFields?.avatar?.id ? user?.avatar?.id : '',
      },
    };
    dispatch(activateUser({ token: cleanedToken, request }))
      .then(data => {
        const webUrl = data?.[0]?.web_url;
        dispatch(addToast({ error: false, text: t('USER_ACTIVATED') }));
        if (webUrl) {
          window.location.replace(webUrl);
        } else {
          navigate('/login');
        }
      })
      .catch(error => {
        const errorText = error?.response?.data?.errorDescription || t('ERROR_WHILE_ACTIVATING_USER');
        setLoading(false);
        dispatch(addToast({ error: true, text: errorText, id: uuid() }));
      });
  };

  useEffect(() => {
    if (cleanedToken && !isTokenExpired) {
      dispatch(getRequiredField(cleanedToken)).then(data => {
        let updatedRequiredField = {};
        (data || []).forEach(item => {
          updatedRequiredField = {
            ...updatedRequiredField,
            [formatText(item.field, '')]: item?.is_required,
          };
        });
        setError({ employeeId: false, phone: false, badgeNumber: false });
        setRequiredFields(updatedRequiredField);
      });
      fetchPasswordRequirements(cleanedToken);
    }
  }, [cleanedToken, isTokenExpired]);

  return (
    <Fragment>
      {isTokenExpired ? (
        <InviteExpire />
      ) : (
        <AccountActivationWrapper className="flex flex-column items-center radius-6 h-fit-content m-auto">
          {applicationDataLoading ? null : AUTH_LOGO ? (
            <img src={AUTH_LOGO?.url} alt="logo" className="custom-logo" />
          ) : (
            <LogoIcon />
          )}
          <div className="bold-text font-20 pt-6">{t('ACTIVATE_YOUR_ACCOUNT')}</div>
          <div className="flex-column pt-6 w-full">
            <label className="regular-text font-12 lighter-text mb-2">
              {t(requiredFields?.phone ? 'PHONE' : 'PHONE_OPTIONAL')}
            </label>
            <div className="input-container">
              <PhoneInput
                selectedCountry={user.phoneCountry}
                setSelectedCountry={country => setUser({ ...user, phoneCountry: country, phone: '' })}
                phone={user.phone}
                setPhone={phone => {
                  setUser({ ...user, phone: phone });
                  setError({ ...error, phone: false });
                  setPhoneNumberError(false);
                }}
                className={error?.phone ? 'phone-input-error' : ''}
              />
            </div>
            {phoneNumberError && (
              <div className="flex items-center password-error  w-full mt-6">
                <ErrorIcon height={16} width={16} />
                <label className="medium-text white-text ml-2 font-12">{phoneNumberError}</label>
              </div>
            )}

            {requiredFields?.employeeid && (
              <div className="w-full flex-column mt-6">
                <label className="regular-text lighter-text mb-1 font-12">
                  {t(requiredFields?.employeeid ? 'EMPLOYEE_NUMBER' : 'EMPLOYEE_NUMBER_OPTINAL')}
                </label>
                <input
                  autoComplete="turnoff"
                  className={`input ${error?.employeeId && 'error-border'}`}
                  onChange={e => {
                    if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                      setUser({ ...user, employeeId: e.target.value });
                      setError({ ...error, employeeId: false });
                    }
                  }}
                  placeholder={t('TYPE_EMPLOYEE_NUMBER')}
                  value={user?.employeeId}
                />
              </div>
            )}
            {requiredFields?.badgenumber && (
              <div className="w-full flex-column mt-6">
                <label className="regular-text lighter-text mb-1 font-12">
                  {t(requiredFields?.badgenumber ? 'BADGE_NUMBER' : 'BADGE_NUMBER_OPTINAL')}
                </label>
                <input
                  autoComplete="turnoff"
                  className={`input ${error?.badgeNumber && 'error-border'}`}
                  onChange={e => {
                    if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                      setUser({ ...user, badgeNumber: e.target.value });
                      setError({ ...error, badgeNumber: false });
                    }
                  }}
                  placeholder={t('TYPE_BADGE_NUMBER')}
                  value={user?.badgeNumber}
                />
              </div>
            )}
            <>
              <div className="flex-column pt-6 w-full">
                <label className="regular-text font-12 lighter-text mb-2"> {t('PASSWORD')}</label>
                <div className="password-requirement-tooltip">
                  <Tooltip
                    className="password-requirement-tooltip-details"
                    shouldOpen={false}
                    isAlwaysOpen={
                      passwordError &&
                      (passwordRequirementError.minumumPasswordLengthError ||
                        passwordRequirementError.upperCaseError ||
                        passwordRequirementError.lowerCaseError ||
                        passwordRequirementError.numberError ||
                        passwordRequirementError.specialCharacterError)
                    }
                    content={passwordRequirementTooltip()}
                    placement="right">
                    <div className="input-container relative">
                      <input
                        placeholder={t('ENTER_YOUR_PASSWORD')}
                        value={password}
                        name="password"
                        className={`input ${passwordError && 'error-input'}`}
                        onChange={e => {
                          setPasswordError(false);
                          setPassword(e.target.value);
                        }}
                        type={showNewPassword ? 'text' : 'password'}
                      />
                      <img
                        className="show-password"
                        src={showNewPassword ? HidePassword : ShowPassword}
                        alt="show password"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="flex-column pt-6 w-full">
                <label className="regular-text font-12 lighter-text mb-2">{t('CONFIRM_PASSWORD')}</label>
                <div className="input-container relative">
                  <input
                    placeholder={t('CONFIRM_YOUR_PASSWORD')}
                    value={confimPassword}
                    name="confirm-password"
                    className={`input ${passwordError && 'error-input'}`}
                    onChange={e => {
                      setPasswordError(false);
                      setConfirmPassword(e.target.value);
                    }}
                    type={showConfirmPassword ? 'text' : 'password'}
                  />
                  <img
                    className="show-password"
                    src={showConfirmPassword ? HidePassword : ShowPassword}
                    alt="show password"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>
              {passwordError && (
                <div className="flex items-center password-error pxy-1 w-full mt-6">
                  <ErrorIcon height={16} width={16} />
                  <label className="medium-text white-text ml-2 font-12">{passwordError}</label>
                </div>
              )}
              <Button
                label={t('ACTIVATE')}
                className="mt-10 primary"
                size="large"
                borderRadius="16px"
                onClick={activate}
                disabled={loading}
              />
            </>
          </div>
        </AccountActivationWrapper>
      )}
    </Fragment>
  );
};

export default AccountActivation;
