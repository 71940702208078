import { ACCOUNT_ACTIVATION_TRANSLATIONS_EN_US } from '../../components/AccountActivation/translation.js';
export const TRANSLATIONS_EN_US = {
  EMAIL_ADDRESS: 'Email address',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  ENTER_PASSWORD: 'Enter password',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot my password',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email or password',
  EMAIL_TO_RECEIVE_CODE: 'Enter your email to receive the security code',
  ENTER_EMAIL_ADDRESS: 'Enter Email address',
  WE_SENT_YOU_EMAIL: 'We sent you an email',
  SEND: 'Send',
  SECURITY_REASON_DETAIL_TEXT:
    'For added security, we need to further verify your account. Please enter the security code here, from your {{type}}',
  INCORRECT_OTP_CODE_ERROR: 'Incorrect code - please check your code is correct and try again',
  VERIFY: 'Verify',
  SUBMIT: 'Submit',
  RESEND_SECURE_CODE: 'Resend secure code',
  RESET_YOUR_PASSWORD: 'Reset your password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  ENTER_YOUR_PASSWORD: 'Enter your password',
  CONFIRM_YOUR_PASSWORD: 'Confirm your password',
  SET_NEW_PASSWORD: 'Set new password',
  SEND_CODE_BY_SMS: 'Send code by SMS',
  SEND_CODE_BY_EMAIL: 'Send code by email',
  SAME_EMAIL_ADDRESS_DETAIL: 'Please ensure you enter the same email address registered with your account',
  PHONE_NUMBER_TO_RECEIVE_CODE: 'Enter your phone number to receive the security code',
  SAME_PHONE_NUMBER_DETAIL: 'Please ensure you enter the same phone number registered with your account',
  PHONE_NUMBER: 'Phone number',
  WE_SENT_YOU_SMS: 'We sent you an SMS',
  CHECK_SMS_CODE: 'Check your SMS and enter your security code below',
  CHECK_EMAIL_CODE: 'Check your email and enter your security code below',
  MINIMUM_PASSWORD_REQUIREMENT:
    'Minimum password 16 characters, at least one upper case, one number, and one special character',
  ADD_NEW: 'Add new',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  EDIT: 'Edit',
  DELETE: 'Delete',
  PRIZE_DRAW_DETAILS: 'Prize Draw details',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  PRIZE_DRAW_CODE: 'Prize draw code',
  STATUS: 'Status',
  FORM: 'Form',
  ENTRANTS: 'Entrants',
  EXPORT: 'Export',
  RESULTS: 'results',
  MENU: 'Menu',
  ENTRANT_NAME: 'Entrant name',
  PAYMENT_STATUS: 'Payment status',
  DRAW_STATUS: 'Draw status',
  ENTRY_DATE: 'Entry date',
  PRODUCT_AMOUNT: 'Product Amount',
  PAYMENT_FREQUENCY: 'Payment frequency',
  WIN_STATUS: 'Win status',
  DETAILS: 'Details',
  FORMS: 'Forms',
  DRAW_CONFIGURATION: 'Draw configuration',
  TIME_ZONE: 'Time Zone',
  TIME_ZONE_PRIZE_DRAW_OPERATE: 'Time zone prize draw will operate',
  START_DATE_TIME: 'Start Date & Time',
  PRIZE_DRAW_START_DATE_TIME: 'Prize draw start date and time',
  ENTRANCE_PARAMETERS: 'Entrance Parameters',
  DATE_ENTRANTS_MUST_ENTER_BY: 'Date entrants must enter by',
  WINNER_DATE_GENERATION: 'Winner Date Generation',
  DATE_WINNERS_WILL_GENERATE: 'Date winners will be generated',
  MAKE_RECURRING: 'Make Recurring',
  GENERATE_WINNERS_CONTINUOUSLY: 'Generate winners continuously',
  RECURRENCE_INTERVAL: 'Recurrence Interval',
  PRIZE_DRAW_RECURRENCE: 'Prize draw recurrence',
  RECURRENCE_PATTERN: 'Recurrence Pattern',
  RECURRING_DRAW_SETTINGS: 'Recurring draw settings',
  TIME_OF_DRAW: 'Time of Draw',
  TIME_OF_PRIZE_DRAW: 'The time of the prize draws',
  END_OF_ENTRY_PERIOD: 'End of Entry Period',
  FINAL_ENTRIES_DATE: 'Final entries date',
  SETTINGS: 'Settings',
  SECURITY: 'Security',
  CONFIGURE_YOUR_SECURITY: 'Configure your security settings',
  USERS: 'Users',
  MANAGE_CURRENT_AND_INVITE_USERS: 'Manage current and invite new users',
  TEAMS: 'Teams',
  MANAGE_AND_INVITE_NEW_TEAMS: 'Manage current and create new teams',
  USER_MANAGEMENT: 'User Management',
  MANAGE_USER_ROLES_AND_TEAMS: 'Manage users, roles and teams',
  NO_DATA: 'No data',
  RECENT_ACTIVITY: 'Recent Activity',
  NETWORK: 'Network',
  CONFIGURATION: 'Configuration',
  ORGANIZATIONS: 'Organizations',
  CONFIGURATION: 'Configuration',
  ENTRY_RESTRICTIONS: 'Entry Restrictions',
  MIN_AGE_TO_ENTER: 'Minimum age to enter',
  F2F_ALLOWED_AGE_RANGE: 'F2F allowed age range',
  ALLOW_PRORATED_CHARGES: 'Allow prorated charges',
  REFERRAL_RULES: 'Referral Rules',
  BONUS_ENTRIES_PER_REFERRAL: 'Bonus entries per referral',
  MAXIMUM_PERMITTED_ENTRIES: 'Maximum permitted entries',
  WINNING_RULES: 'Winning Rules',
  PRIZES: 'Prizes',
  STATES_EXCLUDED: 'States excluded',
  INVITE_USER: 'Invite User',
  NO_DATA: 'No data',
  NO_RESULT: 'No results found',
  TYPE_USER_EMAIL: 'Type user’s email',
  TYPE_USER_NAME: 'Full user’s name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  PHONE_OPTIONAL: 'Phone (optional)',
  USER_ROLE: 'User’s role',
  TYPE_USER_PHONENUMBER: '"Type user’s phone number"',
  EMPLOYEE_NUMBER: 'Employee number',
  BADGE_NUMBER: 'Badge number',
  BADGE_NUMBER_OPTINAL: 'Badge number (optional)',
  TYPE_EMPLOYEE_NUMBER: "Type employee's number",
  TYPE_BADGE_NUMBER: 'Type badge number',
  CANCEL: 'Cancel',
  SEND_INVITE: 'Send invite',
  NO_RESULT_FOUND: 'There aren’t any results for this query.',
  ADD_MARKET_COMPANY: 'Add marketing company',
  REMOVE: 'Remove',
  GROUPS: 'Group',
  GROUP_DETAILS: 'Group details',
  ACCOUNTS: 'Accounts',
  PLATFORMS: 'Platforms',
  AUTOMATIONS: 'Automations',
  AUTOMATION_DETAILS: 'Automation details',
  OWNER: 'Owner',
  ADD_NEW_ACTION: 'Add new action',
  ADD: 'Add',
  ENTER_GROUP_NAME: 'Enter group name',
  ENTER_DESCRIPTION: 'Enter description',
  ADD: 'Add',
  CREATE_ACCOUNT: 'Create account',
  ACCOUNT_INFO: 'Account info',
  ADDRESS: 'Address',
  USER_INFO: 'User info',
  ABBREVIATION: 'Abbreviation',
  NEXT: 'Next',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  ENTER_ADDRESS: 'Enter address',
  CITY: 'City',
  ENTER_CITY: 'Enter city',
  STATE: 'State',
  ZIP_CODE: 'Zip code',
  SELECT_STATE: 'Select State',
  ENTER_ZIPCODE: 'Enter zip code',
  BACK: 'Back',
  CREATE: 'Create',
  ENTER_FULL_NAME: 'Enter full name',
  FORM_DETAILS: 'Form details',
  PRIZE_DRAW: 'Prize Draw',
  LABEL: 'Label',
  CLIENT: 'Client',
  CLIENT_DRAW_CODE: 'Client draw code',
  VERSION_DETAILS: 'Version details',
  FORM_NAME: 'Form Name',
  VERSION: 'Version',
  CREATED: 'Created',
  PUBLISHED: 'Published',
  RETIRED: 'Retired',
  FORM_LOG: 'Form log',
  DUPLICATE: 'Duplicate',
  REPUBLISH: 'Republish',
  RETIRE: 'Retire',
  PUBLISH: 'Publish',
  VERSION_POPUP_TITLE: 'Would you like to {{type}} this version?',
  PUBLISH_VERSION_POPUP_TITLE: 'Are you sure you want to {{type}} this version?',
  CLIENTS: 'Clients',
  FEES: 'Fees',
  ACTIVATE_YOUR_ACCOUNT: 'Activate your account',
  ACTIVATE: 'Activate',
  ENTRANT_DETAILS: 'Entrant details',
  OVERVIEW: 'Overview',
  PAYMENTS: 'Payments',
  PAYMENT_AMOUNT: 'Payment amount',
  PAYMENT_COUNT: 'Payment count',
  COUNT_BY_FREQUENCY: 'Count by frequency',
  WINNINGS: 'Winnings',
  ENTERED_ON: 'Entered on',
  WON: 'Won',
  PAYMENT_METHOD: 'Payment Method',
  NOTES: 'Notes',
  PRODUCT: 'Product',
  CONTACTED_BY: 'Contacted by',
  CANCELED_ON: 'Canceled on',
  NEXT_PAYMENT_ON: 'Next payment on',
  ENTRY_REFERRALS: 'Entry & Referrals',
  ENTRY_FORM: 'Entry form',
  REFERRALS: 'Referrals',
  REFERRAL_SOURCE: 'Referral source',
  ENTRANT: 'Entrant',
  REFEREES: 'Referees',
  LOGS: 'Logs',
  ACTIVITY_LOGS: 'Activity Log',
  ACCOUNT_DETAILS: 'Account details',
  PRIMARY_USER: 'Primary user',
  CONTACT: 'Contact',
  TITLE: 'Title',
  ADDRESS_2: 'Address 2',
  MOBILE: 'Mobile',
  HOME: 'Home',
  ANONYMIZE: 'Anonymize',
  SUCCESSFUL: 'Successful',
  DATE: 'Date',
  ID: 'Id',
  AUTOMATION_LOG: 'Automation Log',
  EVENT: 'Event',
  UPDATE_USER: 'Update user',
  MY_PROFILE: 'My profile',
  USER_BADGE: 'User badge ',
  COMING_SOON: 'Coming soon',
  MFA_CONFIGURATION: 'MFA configuration ',
  SAVE: 'Save ',
  VERIFY_CODE: 'Verify the code sent to your phone ',
  VERIFY_CODE_EMAIL: 'Verify the code sent to your email ',
  SEND_CODE: 'Send Code ',
  USER: 'user ',
  USER_PROFILE: 'User profile',
  PRODUCT_FREQUENCY: 'Product Frequency',
  BADGE_NUMBER: 'Badge Number',
  PLATFORMFEE: 'Platform fee',
  STARTDATE: 'Start on',
  ENDDATE: 'End on',
  PLATFORM_FEE: 'Platform fee',
  START_ON: 'Start on',
  END_ON: 'End on',
  CREATED_DATE: 'Created date',
  SECURITY_CODE_EXPIRED: 'Security code expired',
  DATE_SETTINGS: 'Date settings',
  DATE_RANGE: 'Date range',
  DATE_FORMAT: 'Date format',
  PLAYER_LIST_EXPORT: 'Player list export',
  EXPORT_FILTER: 'Export filter',
  TEMPLATE: 'Template',
  CREATE_TEMPLATE: 'Create template',
  ...ACCOUNT_ACTIVATION_TRANSLATIONS_EN_US,
  INCORRECT_CODE_ERROR: 'Incorrect code - please check your code is correct and try again',
  PASSWORD_MUST: 'Password must',
  STAFF_ADMIN_DASHBOARD: 'Staff Admin dashboard',
  CLIENT_ADMIN_DASHBOARD: 'Client admin dashboard',
  BANIFICIARY_DASHBOARD: 'Beneficiary dashboard',
  DASHBOARD: 'dashboard',
  CURRENT_PLAYERS: 'Current players',
  LAST_6_WEEK: 'last 6 weeks',
  ACTIVE_PLAYERS: 'active players',
  CHANGE_IN_PLAYERS: 'Change in players',
  CURRENT_ENTRIES: 'Current entries',
  ENTRIES: 'entries',
  CHANGE_ENTRIES: 'Change in entries',
  AVERAGE_ENTRIES_PER_PLAYERS: 'Average entries per player',
  FUTURE_DRAW_PROFIT: 'Future draw profit',
  DRAW_PROFIT: 'Draw Profit',
  NUMBER_OF_WINNERS: 'Number of Winners',
  TOTAL_PLAYERS: 'Total Players',
  LENGTH_OF_SUPPORT: 'Length of Support',
  PROJECTED_PROFIT: 'Projected Profit',
  PLAYER_LIST: 'Player List',
  PLAYER_NUMBER: 'Player Number',
  CLIENT: 'Client',
  BENIFICIARY: 'Beneficiary',
  PLAYER_NAME: 'Player Name',
  POSTCODE: 'Postcode',
  FIRST_ENTERED: 'First Entered',
  NO_OF_ENTRIES: 'No of Entries',
  PLAYER_STATUS: 'Player Status',
  DASHBOARD: 'Dashboard',
  ENTRY_PER_PLAYER: 'entries per player',
  ESTIMATED: 'estimated',
};
