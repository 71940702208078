import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.modalBackground};
  top: 0;
  left: 0;
  user-select: none;
  outline: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    display: flex;
    flex-direction: column;
    overflow-y: visible !important;
    background: #ffffff;
    border: 1px solid rgba(240, 246, 249, 0.5);
    box-shadow: 2px 2px 50px rgba(5, 49, 73, 0.2);
    border-radius: 16px;
    outline: 0;
    position: relative;
  }

  .popup-in-appear {
    opacity: 0;
    transform: translate(0px, 15px);
    transition:
      transform 100ms ease-in 50ms,
      opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-active {
    opacity: 0;
    transform: translate(0px, 15px);
    transition:
      transform 100ms ease-in 50ms,
      opacity 100ms ease-in 50ms;
  }

  .popup-in-appear-done {
    opacity: 1;
    transform: unset;
    transition:
      transform 100ms ease-in 50ms,
      opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear {
    opacity: 0;
    transform: translate(0px, 6px);
    transition:
      transform 100ms ease-in,
      opacity 50ms ease-in;
  }

  .popup-fade-appear-active {
    opacity: 0;
    transform: translate(0px, 6px);
    transition:
      transform 100ms ease-in 50ms,
      opacity 100ms ease-in 50ms;
  }

  .popup-fade-appear-done {
    opacity: 1;
    transform: unset;
    transition:
      transform 100ms ease-in 50ms,
      opacity 100ms ease-in 50ms;
  }
  .popup-dashboard-fullscreen {
    height: 100vh;
    width: 100vw;
    padding: 20px;
  }
`;

export const InviteUserWrapper = styled.div`
  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    .organisations-border {
      border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
    }
  }

  .phone-input-error {
    .input {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }
  .provider-selector {
    .organisations-border {
      height: 48px;
    }
    .mc-options {
      top: 53px;
    }
  }
`;

export const UserActionsWrapper = styled.div`
  .user-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
`;

export const PlayerListExportWrapper = styled.div`
  .player-list-wrapper {
    max-width: 1080px;
    min-width: 1080px;
    min-height: 800px;
    max-height: 800px;

    .filter {
      background: ${({ theme }) => theme.colors.backgroundColor};
      border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
      border-radius: 12px;

      :hover {
        border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
      }
    }

    .open-filter-icon {
      background: ${({ theme }) => theme.colors.backgroundColor};
      path {
        stroke: ${({ theme }) => theme.colors.zenPurple};
        fill: ${({ theme }) => theme.colors.zenPurple};
      }
    }
    .prize-draw-entrant-filter {
      left: 40px !important;
      top: 140px !important;
    }

    .filter-box-wrapper {
      background: ${({ theme }) => theme.colors.backgroundColor};
    }

    .provider-selector {
      .organisations-border {
        height: 40px;
        background: #ffff;
      }
      .mc-options {
        top: 48px;
        max-height: 260px;
      }
    }

    .left-panel-wrapper {
      width: calc(52% - 20px);
    }
    .right-panel-wrapper {
      width: calc(48% - 20px);
    }

    .filter-box-input {
      min-width: 40%;
      max-width: 40%;
      background: #ffff;
      height: 40px;
    }

    .template-item-wrapper {
      border: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .selected-template {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }

    .column-item-wrapper {
      border: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .sub-item-wrapper {
      border-top: 1px solid ${({ theme }) => theme.colors.divider};
    }

    .edit-template-wrapper {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }

    .update-btns {
      top: 85px;
    }

    .edit-input {
      height: 32px;
      font-size: 14px;
    }

    .date-format-dropdown {
      height: 40px;
      background: #ffff;
    }
    .selected-date-format-dropdown {
      border: 1px solid ${({ theme }) => theme.colors.zenPurple};
    }
    .date-format-option-wrapper {
      top: 45px;
      max-height: 250px;
      z-index: 5;
      .date-format-label {
        text-transform: uppercase;
      }
      .sub-option-wrapper {
        row-gap: 2px;
      }
      .date-format-option {
        padding: 6px 8px;
      }
      .selected-option {
        background: ${({ theme }) => theme.colors.backgroundColor};
        label {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
      .date-format-option:hover {
        background: ${({ theme }) => theme.colors.backgroundColor};
        label {
          color: ${({ theme }) => theme.colors.zenPurple};
        }
      }
    }
    .date-format-input {
      max-width: 170px;
    }
    .date-range-wrapper {
      left: 0px !important;
      top: 40px;
      .date-selector {
        height: 270px;
      }
      .no-select {
        margin-bottom: 8px;
      }
      .month-selector {
        margin-bottom: 12px;
      }
      .date-container-wrapper {
        min-height: 320px;
        max-height: 320px;
      }
    }
  }

  .template-details-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 16px;
  }
`;
