import React from 'react';
import Overview from '../../components/Overview';

const OverviewPage = ({ expanded }) => {
  return (
    <div
      className={`flex flex-1 ${expanded ? 'overview-expand' : 'overview-without-expand'}`}
      style={{ maxHeight: '100dvh', maxWidth: '100dvw', width: '100vh' }}>
      <Overview expanded={expanded} />
    </div>
  );
};

export default OverviewPage;
