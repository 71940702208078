import React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Activate from '../pages/activate';
import ForgotPassword from '../pages/forgot-password';
import LoginPage from '../pages/login';
import Main from '../pages/main/main';
import MultiFactorAuth from '../pages/multi-factor-auth';
import PlayerExportDetails from '../pages/player-detail-export';
import Toast from '../popup/toast/toast';
import { AuthenticationRoute } from './authenticationRoute';
import history from './history';
import { PrivateRoute } from './privateRoute';

export const Router = () => {
  return (
    <HashRouter history={history}>
      <Routes>
        <Route element={<LoginPage />} path="/login" />
        <Route element={<ForgotPassword />} path="/password" />
        <Route element={<Activate />} path="/user/activate" />

        <Route element={<AuthenticationRoute />}>
          <Route element={<MultiFactorAuth />} path="/authentication" />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route element={<PlayerExportDetails />} path="/exports/:id" />
          <Route element={<Main />} path="/*" />
        </Route>
      </Routes>
      <Toast />
    </HashRouter>
  );
};
