import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HidePassword from '../../assets/images/hide-password.svg';
import Logo from '../../assets/images/logo.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import { ReactComponent as LoginErrorIcon } from '../../assets/images/vector.svg';
import TextLogo from '../../assets/images/zenterprize.svg';
import Button from '../../components/Elements/button/button';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import { LoginWrapper } from '../../styles/page/login.styled';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { applicationData, applicationDataLoading } = useSelector(state => state.application);
  const { images } = applicationData || {};
  const { AUTH_LOGO } = images || {};

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    if (isValid()) {
      try {
        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/authentication`, {
          identity: email,
          password: password,
        });
        const userData = response.data;
        dispatch(setUser(userData));
        setItemInLocalStorage('user', userData);
        navigate('/authentication');
      } catch (error) {
        if (error?.response?.data?.error_code === 'UNAUTHORIZED') {
          dispatch(
            addToast({
              error: true,
              text: error?.response?.data?.error_description
                ? error?.response?.data?.error_description
                : 'Incorrect email or password',
            }),
          );
        }
        setShowError(true);
      }
    }
  };

  const isValid = () => {
    return email.length > 2 && password.length > 2;
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = e => {
    setEmail(e.target.value);
    setShowError(false);
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value);
    setShowError(false);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleLogin();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [email, password]);

  return (
    <LoginWrapper>
      <div className="login-page">
        <div className="login">
          <div className="login-form card px-16 py-14">
            {applicationDataLoading ? null : AUTH_LOGO ? (
              <img className="custom-logo" src={AUTH_LOGO.url} alt="logo" />
            ) : (
              <>
                <img className="logo" src={Logo} alt="logo" />
                <img className="text-logo" src={TextLogo} alt="text-logo" />
              </>
            )}
            <div className="flex-column form w-full">
              <label className="input-label mb-2">{t('EMAIL_ADDRESS')}</label>
              <div className="input-container">
                <input
                  placeholder={t('EMAIL_ADDRESS')}
                  value={email}
                  onChange={handleEmailChange}
                  className={showError ? 'error-border' : ''}
                />
              </div>
              <label className="input-label mb-2">{t('PASSWORD')}</label>
              <div className="input-container">
                <input
                  className={`password-input ${showError ? 'error-border' : ''}`}
                  type={showPassword ? 'text' : 'password'}
                  placeholder={t('ENTER_PASSWORD')}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <img
                  className="show-password"
                  src={showPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={togglePasswordVisibility}
                />
              </div>
              {showError && (
                <div className="error-message mt-6">
                  <LoginErrorIcon className="error-icon mr-2" />
                  <label className="regular-text color-white">{t('INCORRECT_EMAIL_PASSWORD')}</label>
                </div>
              )}
              <Button
                label={t('LOGIN')}
                className={`mt-10 mb-6 ${isValid() && !showError ? 'primary' : 'secondary'}`}
                size="large"
                borderRadius="16px"
                onClick={handleLogin}
                width="350px"
              />
              <a href="#/password" className="color-purple medium-text font-12 mb-2">
                {t('FORGOT_PASSWORD')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default LoginPage;
