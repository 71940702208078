import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ExportIcon } from '../../assets/images/download.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import Button from '../../components/Elements/button/button';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ReactComponent as CalenderIcon } from '../../assets/images/calendar_icon.svg';
import { ReactComponent as CalenderIconPurple } from '../../assets/images/calender-icon-purple.svg';
import { ReactComponent as RightArrowIconPurple } from '../../assets/images/sorting-right-purple.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/images/sorting-right.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { PageHeaderWrapper } from '../../styles/components/page-header/page-header.styled';
import InputSearch from '../common/input-search';
import Pagination from '../pagination/pagination';

const PageHeader = ({
  names = [],
  searchText,
  onSearchChange,
  showSearch,
  showAddNewBtn,
  showPagination = false,
  pagination = {},
  onAddClick = () => {},
  addBtnPermission = '',
  title = '',
  className = '',
  showExportButton = false,
  exportButtonPermission = '',
  onExportClick = () => {},
  showDateRangeFilter = false,
  startDate = '',
  endDate = '',
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { hasPermission, checkPermission } = useContext(OrganisationContext);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dateRangeSelected, setDateRangeSelected] = useState(false);

  const onDateRangeBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenDateRange(false);
    }
  };

  return (
    <PageHeaderWrapper className={`flex items-center w-full  ${className}`}>
      {names && names.length > 0 && (
        <div className="flex flex-1">
          {names.map((name, i) => (
            <div key={i} className="flex items-center">
              <label
                className={`${i < names.length - 1 ? 'regular-text grey-text' : 'regular-text'} cursor`}
                onClick={() => (name.onClick ? name.onClick() : navigate(name.path))}>
                {name.text}
              </label>
              {i < names.length - 1 && (
                <span className={`regular-text ml-1 mr-1 ${i < names.length - 2 ? 'grey-text' : 'zen-default-text'}`}>
                  /
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      {title && <label className="semibold-text font-20 flex flex-1 zen-default-text">{title}</label>}
      {showAddNewBtn && (
        <Button
          fontSize="14px"
          size="medium"
          width="110px"
          borderRadius="12px"
          className={`default-secondary ml-6 ${!hasPermission(addBtnPermission) && 'disabled'}`}
          icon={<PlusIcon height={16} width={16} />}
          label={t('ADD_NEW')}
          onClick={() => checkPermission(() => onAddClick(), addBtnPermission)}
        />
      )}
      {showExportButton && hasPermission(exportButtonPermission) && (
        <Button
          className={`primary-grey ml-6 export-btn`}
          label={t('EXPORT')}
          borderRadius={'12px'}
          size={'medium'}
          icon={<ExportIcon height={16} width={16} />}
          onClick={() => checkPermission(() => onExportClick(), exportButtonPermission)}
        />
      )}
      {showSearch && (
        <InputSearch
          placeholder={'Search'}
          value={searchText}
          onChange={onSearchChange}
          className="ml-6 input-search"
        />
      )}
      {showDateRangeFilter && (
        <div className="relative" onBlur={onDateRangeBlur} tabIndex={0}>
          <div
            className="flex items-center radius-3 border date-range-container ml-4 px-2 col-gap-1 cursor"
            onClick={() => setOpenDateRange(!openDateRange)}>
            {dateRangeSelected ? (
              <>
                <CalenderIconPurple height={16} width={16} />
                <label className="regular-text zen-purple-text">
                  {moment(startDate * 1000).format('MMM DD, YYYY')}
                </label>
                <RightArrowIconPurple />
                <label className="regular-text zen-purple-text">{moment(endDate * 1000).format('MMM DD, YYYY')}</label>
              </>
            ) : (
              <>
                <CalenderIcon height={16} width={16} color={theme.colors.ZenDefaultSecondary} />
                <label className="regular-text ">{moment(startDate * 1000).format('MMM DD, YYYY')}</label>
                <RightArrowIcon />
                <label className="regular-text">{moment(endDate * 1000).format('MMM DD, YYYY')}</label>
              </>
            )}
          </div>
        </div>
      )}
      {showPagination && (
        <Pagination
          className="ml-6"
          selectedPage={pagination.selectedPage}
          setSelectedPage={page => {
            pagination.setSelectedPage && pagination.setSelectedPage(page);
          }}
          totalPages={pagination.totalPages}
        />
      )}
    </PageHeaderWrapper>
  );
};

export default PageHeader;
