import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Elements/button/button';
import DateSelector from '../../widget/date-selector/date-selector';
import { DateRangeStyledWrapper } from './date-range.styled';

const DateRangeComponent = ({
  className = '',
  startDate = moment().unix(),
  endDate = moment().unix(),
  handleApplyDates = () => {},
  handleCancelDates = () => {},
  setDateRangeSelected,
}) => {
  const [currentStartdate, setCurrentStartdate] = useState(startDate);
  const [currentEnddate, setCurrentEnndate] = useState(endDate);
  const [clearStartDate, setClearStartDate] = useState(false);
  const [clearEndDate, setClearEndDate] = useState(false);
  const { t } = useTranslation();
  const handleClearFilter = () => {
    if (clearStartDate && clearEndDate) {
      setDateRangeSelected(false);
    }
  };
  return (
    <DateRangeStyledWrapper className={`flex-column card absolute radius-4 ${className}`}>
      <div className="flex w-full date-container-wrapper">
        <div className="flex-column w-50 px-6 py-5 border-right">
          <div
            className="flex items-center justify-start cursor clear-all no-select mb-4"
            onClick={() => {
              setCurrentStartdate(moment().subtract(1, 'year').unix()), setClearStartDate(true);
            }}>
            <label className="normal-text" style={{ color: '#FF406E' }}>
              {t('CLEAR')}
            </label>
          </div>
          <div className="relative">
            <DateSelector
              range={true}
              setEndDate={() => {}}
              isFromDateRange={true}
              setStartDate={setCurrentStartdate}
              startDate={moment.unix(currentStartdate)}
              endDate={moment.unix(currentEnddate)}
              disablePastDays={false}
              disableFutureDaysOfDate={true}
              disableFutureDayDate={currentEnddate}
              showCurrentMonth={false}
            />
          </div>
        </div>
        <div className="flex-column w-50 px-6 py-5 border-right">
          <div
            className="flex items-center justify-start cursor clear-all no-select mb-4"
            onClick={() => {
              setCurrentEnndate(moment().unix()), setClearEndDate(true);
            }}>
            <label className="normal-text" style={{ color: '#FF406E' }}>
              {t('CLEAR')}
            </label>
          </div>
          <div className="relative">
            <DateSelector
              range={true}
              isForEndDatePicker={true}
              setEndDate={setCurrentEnndate}
              isFromDateRange={true}
              setStartDate={() => {}}
              startDate={moment.unix(currentStartdate)}
              endDate={moment.unix(currentEnddate)}
              disablePastDays={false}
              disablePastDaysOfDate={true}
              disablePastDayDate={currentStartdate}
              showCurrentMonth={false}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center bottom-panel px-6 w-full">
        <div className="flex items-center col-gap-3">
          <div className="date-value-container flex items-center radius-4 px-3">
            <label className="regular-text font-16">{moment(currentStartdate * 1000).format('MMM DD, YYYY')}</label>
          </div>
          <label className="regular-text lighter-text font-16">-</label>
          <div className="date-value-container flex items-center radius-4 px-3">
            <label className="regular-text font-16">{moment(currentEnddate * 1000).format('MMM DD, YYYY')}</label>
          </div>
        </div>
        <div className="flex items-center col-gap-3">
          <Button
            label={'Cancel'}
            size={'average'}
            borderRadius={'12px'}
            width={'100%'}
            className={'primary-white action-btn'}
            onClick={() => handleCancelDates()}
          />
          <Button
            onClick={() => {
              handleApplyDates({ startDate: currentStartdate, endDate: currentEnddate }), handleClearFilter();
            }}
            label={'Apply'}
            size={'average'}
            borderRadius={'12px'}
            width={'100%'}
            className={'primary action-btn'}
          />
        </div>
      </div>
    </DateRangeStyledWrapper>
  );
};

export default DateRangeComponent;
