import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as InviteExpireIcon } from '../../assets/images/invite-expire.svg';
import { ReactComponent as LogoIcon } from '../../assets/images/zenterprize-logo-text.svg';
import { InviteWrapper } from '../../styles/invite/invite.styled';

const InviteExpire = () => {
  const { t } = useTranslation();
  const { applicationData, applicationDataLoading } = useSelector(state => state.application);
  const { images } = applicationData || {};
  const { AUTH_LOGO } = images || {};

  return (
    <InviteWrapper className="flex flex-column items-center radius-6 h-fit-content m-auto">
      {applicationDataLoading ? null : AUTH_LOGO ? (
        <img src={AUTH_LOGO?.url} alt="logo" className="custom-logo" />
      ) : (
        <LogoIcon />
      )}
      <div className="bold-text font-20 pt-6 px-16 text-center">{t('INVITE_EXPIRED')}</div>
      <div className="regular-text font-14 pt-2 lighter-text">{t('INVITE_EXPIRED_SUBTEXT')}</div>
      <div className="pt-10">
        <InviteExpireIcon />
      </div>
    </InviteWrapper>
  );
};

export default InviteExpire;
