export const ACCOUNT_ACTIVATION_TRANSLATIONS_EN_US = {
  INVITE_EXPIRED: 'Whoops, looks like this invitation has expired',
  INVITE_EXPIRED_SUBTEXT: 'Please ask your Organization to re-send the invite.',
  PASSWORD_MUST: 'Password must:',
  HAVE_MIN_LENGTH_CHARACTERS: 'Have {{min_length}} characters',
  HAVE_MIN_UPPERCASE: 'Have {{min_uppercase}} uppercase',
  HAVE_MIN_LOWERCASE: 'Have {{min_lowercase}} lowercase',
  HAVE_MIN_NUMBER: 'Have {{min_digits}} number',
  HAVE_MIN_SPECIAL_CHARACTER: 'Have {{min_special}} special character',
  PLEASE_FILL_REQUIRED_FIELDS: 'Please fill in all required fields',
  PLEASE_ENTER_VALID_PHONE_NUMBER: 'Please enter a valid phone number',
  PHONE_NUMBER_NOT_VALID: 'Phone number is not valid',
  BADGE_NUMBER_CHARACTER_LIMIT: 'Badge number should be 8 character',
  PASSWORD_NOT_MEET_MINIMUM_REQUIREMENT: 'Password does not meet the minimum requirements',
  PASSWORD_NOT_MATCH: 'Password does not match',
  USER_ACTIVATED: 'User activated',
  ERROR_WHILE_ACTIVATING_USER: 'Error while activating user',
};
