import styled from 'styled-components';

export const PageHeaderWrapper = styled.div`
  .filter {
    background: ${({ theme }) => theme.colors.backgroundColor};
    border: 1px solid ${({ theme }) => theme.colors.backgroundColor};
    border-radius: 12px;

    :hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }
  }

  .open-filter-icon {
    background: ${({ theme }) => theme.colors.backgroundColor};
    path {
      stroke: ${({ theme }) => theme.colors.zenPurple};
      fill: ${({ theme }) => theme.colors.zenPurple};
    }
  }

  .input-search {
    .input {
      width: 280px;
    }
  }

  .export-btn {
    label {
      font-family: Poppins Regular;
    }
  }

  ${({ theme: { down, breakpoints } }) => `
  ${down(breakpoints.lg)} {
    .input-search {
      .input {
        width: 200px;
      }
    }
  }
`}

  .draw-count-down {
    background: ${({ theme }) => theme.colors.ZenPositiveSecondary};
    border: 1px solid ${({ theme }) => theme.colors.ZenPositive};
    min-height: 32px;
    max-height: 32px;
  }

  .date-range-container {
    background: ${({ theme }) => theme.colors.backgroundColor};
    max-height: 32px;
    min-height: 32px;
    border: 1px solid ${({ theme }) => theme.colors.divider};
  }
`;
