import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import MultiFactorSetup from '../pages/multi-factor-setup';

export const AuthenticationRoute = () => {
  const authed = JSON.parse(localStorage.getItem('user'));
  const { multifactor_token, multifactor_options } = authed || {};
  if (!authed) {
    return <Navigate replace to={'/login'} />;
  }
  if (multifactor_token) {
    if (multifactor_options && multifactor_options.length > 0) {
      return <Outlet />;
    } else {
      return <MultiFactorSetup />;
    }
  } else {
    return <Navigate replace to={'/overview'} />;
  }
};
