import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BarColumnChart from '../Charts/BarColumnChart';

const WinnerNumber = ({ expanded }) => {
  const { winnerNumberStats } = useSelector(state => state?.overview);

  const { t } = useTranslation();

  const seriesData = [
    winnerNumberStats?.week,
    winnerNumberStats?.quarter,
    winnerNumberStats?.half_year,
    winnerNumberStats?.year,
  ];
  const xaxisData = ['This week', '3 months', '6 months', '12 months'];

  return (
    <div
      className={`border radius-4 pxy-4 flex-column flex-1 h-full ${expanded ? 'winner-chart' : 'chart-respo'}`}
      style={{ height: 300, width: 415 }}>
      <div className="flex justify-between">
        <label className="font-16 medium-text mb-4">{t('NUMBER_OF_WINNERS')}</label>
      </div>
      <div className="bar-column-chart">
        <BarColumnChart seriesData={seriesData} xaxisData={xaxisData} Tootltiptile={'Winners'} />
      </div>
    </div>
  );
};

export default WinnerNumber;
