import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as ArrowIcon } from '../../assets/images/profit-arrow.svg';
import { calculatePercentageIncrease, getFormattedNumber } from '../../helpers/utils';
import SplineChart from '../Charts/SplineChart';

const DrawProfit = ({ expanded }) => {
  const { drawProfitStats } = useSelector(state => state?.overview);

  const { t } = useTranslation();

  let seriesData = Object.values(drawProfitStats || {})?.map(item => item?.profit);
  const xaxisData = Object.keys(drawProfitStats || {})?.map(item => moment.unix(item).format('DD/MM'));
  const isOnlyOneData = seriesData?.length === 1;

  seriesData = isOnlyOneData ? [...seriesData, 0] : seriesData;

  const firstValue = seriesData?.at(0);
  const lastValue = seriesData?.at(-1);

  const totalPercentageIncrease = firstValue && lastValue ? calculatePercentageIncrease(firstValue, lastValue) : 0;

  return (
    <div
      className={`border radius-4 pxy-4 flex-column flex-1 h-full ${expanded ? 'line-chart-draw' : 'chart-respo'}`}
      style={{ height: 300, width: 415 }}>
      <div className="flex justify-between">
        <label className="font-16 medium-text mb-4">{t('DRAW_PROFIT')}</label>
        {totalPercentageIncrease ? (
          <div
            className={classNames(
              'col-gap-7px flex items-center justify-center radius-2 w-60px border-none font-12 medium-text h-22px',
              totalPercentageIncrease >= 0 && 'bg-green green-positive-text',
              totalPercentageIncrease < 0 && 'bg-negative red-text',
            )}>
            <ArrowIcon
              width={8}
              height={8}
              className={classNames(
                totalPercentageIncrease >= 0 && 'green-positive-text',
                totalPercentageIncrease < 0 && 'red-text rotate-180',
              )}
            />
            {Math.abs(totalPercentageIncrease)}%
          </div>
        ) : null}
      </div>
      <div className="line-chart-over ">
        <SplineChart
          tooltipValueFormatter={value => getFormattedNumber(value || 0)}
          seriesData={seriesData}
          xaxisData={xaxisData}
          Tootltiptile={'Draw Profit'}
          formate={true}
        />
      </div>
    </div>
  );
};

export default DrawProfit;
