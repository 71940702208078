import { combineReducers, configureStore } from '@reduxjs/toolkit';
import applicationSlice from './features/applicationSlice';
import clientOrgSlice from './features/clientOrgSlice';
import overviewSlice from './features/overviewSlice';
import playerListSlice from './features/playerListSlice';
import profileSlice from './features/profileSlice';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';
import userManagementSlice from './features/userManagementSlice';
import userSlice from './features/userSlice';

const combinedReducer = combineReducers({
  reload: reloadSlice,
  toast: toastSlice,
  user: userSlice,
  profile: profileSlice,
  usersManagement: userManagementSlice,
  overview: overviewSlice,
  clientOrg: clientOrgSlice,
  playerlist: playerListSlice,
  application: applicationSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/setUserLogout') {
    state = {
      application: state.application,
    };
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_STAGE === 'development',
});
