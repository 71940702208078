import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropdownIcon } from '../../assets/images/dropdown.svg';
import DropDown from '../../components/McDropdown';
import DateRangeComponent from '../../components/common/date-range';
import { dateRangeOption } from '../../constant/InitialData';

const leftDateFormatOptions = [
  {
    label: 'Regional (default)',
    options: [
      {
        groupName: 'Regional (default)',
        id: 'DD/MM/YYYY',
        name: moment().format('DD/MM/YYYY'),
        value: 'DD/MM/YYYY',
        param: 'dd/MM/YYYY',
      },
    ],
  },
  {
    label: 'Long date',
    options: [
      {
        groupName: 'Long date',
        id: 'MMMM DD, YYYY',
        name: moment().format('MMMM DD, YYYY'),
        value: 'MMMM DD, YYYY',
        param: 'MMMM dd, YYYY',
      },
      {
        groupName: 'Long date',
        id: 'MMM DD, YYYY',
        name: moment().format('MMM DD, YYYY'),
        value: 'MMM DD, YYYY',
        param: 'MMM dd, YYYY',
      },
      {
        groupName: 'Long date',
        id: 'DD-MMM-YYYY',
        name: moment().format('DD-MMM-YYYY'),
        value: 'DD-MMM-YYYY',
        param: 'dd-MMM-YYYY',
      },
      {
        groupName: 'Long date',
        id: 'YYYY-DD-MM',
        name: moment().format('YYYY-DD-MM'),
        value: 'YYYY-DD-MM',
        param: 'YYYY-dd-MM',
      },
      {
        groupName: 'Long date',
        id: 'YYYY.DD.MM',
        name: moment().format('YYYY.DD.MM'),
        value: 'YYYY.DD.MM',
        param: 'YYYY.dd.MM',
      },
    ],
  },
  {
    label: 'Day and Date',
    options: [
      {
        groupName: 'Day and Date',
        id: 'dddd, MMMM D, YYYY',
        name: moment().format('dddd, MMMM D, YYYY'),
        value: 'dddd, MMMM D, YYYY',
        param: 'EEEE, MMMM dd, YYYY',
      },
      {
        groupName: 'Day and Date',
        id: 'ddd DD-MMM-YYYY',
        name: moment().format('ddd DD-MMM-YYYY'),
        value: 'ddd DD-MMM-YYYY',
        param: 'EEE dd-MMM-YYYY',
      },
      {
        groupName: 'Day and Date',
        id: 'ddd DD/MM/YYYY',
        name: moment().format('ddd DD/MM/YYYY'),
        value: 'ddd DD/MM/YYYY',
        param: 'EEE dd/MM/YYYY',
      },
    ],
  },
];

const rightDateFormatOptions = [
  {
    label: 'Day and time',
    options: [
      {
        groupName: 'Day and time',
        id: 'DD/MM/YYYY h:mm A',
        name: moment().format('DD/MM/YYYY h:mm A'),
        value: 'DD/MM/YYYY h:mm A',
        param: 'dd/MM/YYYY h:mm a',
      },
    ],
  },
  {
    label: 'Short date',
    options: [
      { groupName: 'Short date', id: 'MMMM DD', name: moment().format('MMMM DD'), value: 'MMMM DD', param: 'MMMM dd' },
      { groupName: 'Short date', id: 'DD, MMM', name: moment().format('DD, MMM'), value: 'DD, MMM', param: 'dd, MMM' },
    ],
  },
];

const FilterBox = ({
  className,
  selectedRangeData,
  setSelectedDateRange = () => {},
  setSelectedDateFormat = () => {},
  selectedDateFormat = {},
}) => {
  const [showDataFormatOption, setShowDateFormatOption] = useState(false);
  const [openDateRange, setOpenDateRange] = useState(false);
  const { t } = useTranslation();
  const getDateLabel = () => {
    switch (selectedRangeData?.value) {
      case 'TODAY': {
        return moment(selectedRangeData?.startDate * 1000).format('DD/MM/YYYY hh:mm A');
      }
      case 'CURRENT_MONTH':
      case 'LAST_7_DAYS':
      case 'LAST_MONTH':
      case 'ALL':
      case 'CUSTOM': {
        return `${moment(selectedRangeData?.startDate * 1000).format('DD/MM/YYYY hh:mm A')} - ${moment(
          selectedRangeData?.endDate * 1000,
        ).format('DD/MM/YYYY hh:mm A')}`;
      }
    }
  };

  const getDateInputText = () => {
    switch (selectedRangeData?.value) {
      case 'TODAY': {
        return moment(selectedRangeData?.startDate * 1000).format('MMM DD');
      }
      case 'CURRENT_MONTH':
      case 'LAST_7_DAYS':
      case 'LAST_MONTH':
      case 'ALL': {
        return `${moment(selectedRangeData?.startDate * 1000).format('MMM DD')} - ${moment(
          selectedRangeData?.endDate * 1000,
        ).format('MMM DD')}`;
      }
      case 'CUSTOM': {
        return `${moment(selectedRangeData?.startDate * 1000).format('MMM DD')} - ${moment(
          selectedRangeData?.endDate * 1000,
        ).format('MMM DD')}`;
      }
    }
  };

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowDateFormatOption(false);
    }
  };

  const onDateRangeBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setOpenDateRange(false);
    }
  };

  const handleSelectedDateFormat = formatOption => {
    setShowDateFormatOption(false);
    setSelectedDateFormat(formatOption);
  };

  const dateFormatOptions = (
    <div className="absolute flex card pxy-4 radius-4 w-full date-format-option-wrapper col-gap-2 overflow-scroll">
      <div className="flex-column h-fit-content w-50 row-gap-4">
        {leftDateFormatOptions.map(dateGroup => (
          <div className="flex-column row-gap-4">
            <label className="semibold-text date-format-label">{dateGroup?.label}</label>
            <div className="flex-column w-full sub-option-wrapper">
              {(dateGroup?.options || [])?.map(formatOption => (
                <div
                  className={`flex items-center radius-2 date-format-option cursor px-2 ${
                    selectedDateFormat.id == formatOption.id ? 'selected-option' : ''
                  }`}
                  onClick={() => handleSelectedDateFormat(formatOption)}>
                  <label className="regular-text">{formatOption?.name}</label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="flex-column h-fit-content w-50 row-gap-4">
        {rightDateFormatOptions.map(dateGroup => (
          <div className="flex-column row-gap-4">
            <label className="semibold-text date-format-label">{dateGroup?.label}</label>
            <div className="flex-column w-full sub-option-wrapper">
              {(dateGroup?.options || [])?.map(formatOption => (
                <div
                  className={`flex items-center radius-2 date-format-option cursor px-2 ${
                    selectedDateFormat.id == formatOption.id ? 'selected-option' : ''
                  }`}
                  onClick={() => handleSelectedDateFormat(formatOption)}>
                  <label className="regular-text">{formatOption?.name}</label>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  const dateFormatComponent = (
    <div className="flex-column relative" onBlur={onBlur} tabIndex={0}>
      <div
        className={`flex items-center justify-between organisations-border radius-16 w-full date-format-dropdown radius-4 px-4 py-2 col-gap-4 ${
          showDataFormatOption ? 'selected-date-format-dropdown' : ''
        }`}>
        <div
          className="flex items-center justify-between flex-1 cursor"
          onClick={() => setShowDateFormatOption(!showDataFormatOption)}>
          <label className="regular-text font-16 one-line flex-1">{selectedDateFormat?.groupName}</label>
          <label className="regular-text lighter-text font-16 one-line">{selectedDateFormat?.name}</label>
        </div>
        <DropdownIcon />
      </div>
      {showDataFormatOption && dateFormatOptions}
    </div>
  );

  return (
    <div className={`flex-column w-full radius-4 pxy-6 row-gap-6 filter-box-wrapper ${className}`}>
      <div className="flex items-center col-gap-6">
        <label className="regular-text semibold-text font-18 flex-1 one-line">{t('DATE_SETTINGS')}</label>
        <label className="regular-text font-14 text-right">{getDateLabel()}</label>
      </div>
      <div className="flex-column row-gap-1">
        <label className="regular-text lighter-text font-12">{t('DATE_RANGE')}</label>
        <div className="flex w-full col-gap-6">
          <DropDown
            className="provider-selector w-full flex-1"
            options={dateRangeOption}
            selected={selectedRangeData}
            setSelected={option => setSelectedDateRange(option)}
            menuHeight={'auto'}
          />
          <div className="relative" onBlur={onDateRangeBlur} tabIndex={0}>
            <input
              autoComplete="turnoff"
              className={`input filter-box-input date-format-input ${
                selectedRangeData?.value === 'CUSTOM' ? 'cursor' : ''
              }`}
              onChange={e => {}}
              value={getDateInputText()}
              onClick={() => setOpenDateRange(!openDateRange)}
              disabled={selectedRangeData?.value === 'CUSTOM' ? false : true}
            />
            {openDateRange && (
              <DateRangeComponent
                startDate={selectedRangeData?.startDate}
                endDate={selectedRangeData?.endDate}
                handleApplyDates={data => {
                  setSelectedDateRange({ ...selectedRangeData, startDate: data?.startDate, endDate: data?.endDate });
                  setOpenDateRange(false);
                }}
                handleCancelDates={() => setOpenDateRange(false)}
                setDateRangeSelected={() => {}}
                className="date-range-wrapper"
              />
            )}
          </div>
        </div>
      </div>
      <div className="flex-column row-gap-1">
        <label className="regular-text lighter-text font-12">{t('DATE_FORMAT')}</label>
        {dateFormatComponent}
      </div>
    </div>
  );
};

export default FilterBox;
