import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {},
  reducers: {
    setCurruntOverviewStats: (state, { payload }) => {
      state.currentOverviewStats = payload;
    },
    setSupportStats: (state, { payload }) => {
      state.supportStats = payload;
    },
    setDrawProfitStats: (state, { payload }) => {
      state.drawProfitStats = payload;
    },
    setWinnerNumberStats: (state, { payload }) => {
      state.winnerNumberStats = payload;
    },
    setProfitProjectStats: (state, { payload }) => {
      state.profitProjectStats = payload;
    },
  },
});

export const getCurruntPlayers = payload => async dispatch => {
  const { params = {} } = payload || {};
  try {
    const { data } = await api.get('/players/stats/current', { params });
    dispatch(setCurruntOverviewStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getPlayersSupport = payload => async dispatch => {
  const { params = {} } = payload || {};

  try {
    const { data } = await api.get('/players/stats/support', { params });
    dispatch(setSupportStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDrawProfit = payload => async dispatch => {
  const { params = {} } = payload || {};

  try {
    const { data } = await api.get('/players/stats/draws', { params });
    dispatch(setDrawProfitStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getWinnerNumber = payload => async dispatch => {
  const { params = {} } = payload || {};

  try {
    const { data } = await api.get('/players/stats/winners', { params });
    dispatch(setWinnerNumberStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getProfitProject = payload => async dispatch => {
  const { params = {} } = payload || {};

  try {
    const { data } = await api.get('/players/stats/profit', { params });
    dispatch(setProfitProjectStats(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {
  setCurruntOverviewStats,
  setSupportStats,
  setDrawProfitStats,
  setWinnerNumberStats,
  setProfitProjectStats,
} = overviewSlice.actions;
export default overviewSlice.reducer;
