import React, { useState } from 'react';
import { ReactComponent as DropdownIcon } from '../../assets/images/dropdown.svg';
import Checkbox from '../../components/common/checkbox';

const ColumnItem = ({ handleCheckColumn = () => {}, column = {} }) => {
  const [showDetails, setShowDetails] = useState(true);
  return (
    <div className="flex-column column-item-wrapper radius-4">
      <div className=" flex items-center  px-4 py-3">
        <div className="flex col-gap-4 flex-1">
          <Checkbox onChange={checked => handleCheckColumn(column?.id, checked)} checked={column?.selected || false} />
          <p className="medium-text font-16">{column?.name}</p>
        </div>
        <DropdownIcon
          className={`flex items-center justify-center cursor ${!showDetails ? 'rotate-270' : ''}`}
          height={16}
          width={16}
          onClick={() => setShowDetails(!showDetails)}
        />
      </div>
      {showDetails && column?.columns?.length > 0 && (
        <div className="flex items-center wrap pxy-4 col-gap-8 row-gap-4 sub-item-wrapper w-full">
          {(column?.columns || []).map(data => (
            <div className="flex items-center col-gap-2 w-50">
              <Checkbox
                checked={data?.selected || false}
                onChange={checked => handleCheckColumn(column?.id, checked, data?.id)}
              />
              <p className="regular-text one-line flex-1">{data?.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColumnItem;
