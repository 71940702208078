const themeHelper = {
  down: breakpoint => `@media screen and (max-width: ${breakpoint})`,
  up: breakpoint => `@media screen and (min-width: ${breakpoint})`,
  breakpoints: {
    lg: '1024px',
    xl: '1920px',
  },
};

export const light = {
  ...themeHelper,
  colors: {
    primary: '#4851f4',
    backgroundColor: '#F5F9FC',
    sidebarBackground: '#FDFDFE',
    hoverColor: '#f5f9fc80',
    text: '#202224',

    modalBackground: 'rgba(22, 25, 44, 0.8)',
    divider: '#E2E7EF',

    ZenDefault: '#2B2B7C',
    zenPurple: '#8927EF',

    zenGray: '#A0A0A0',

    ZenDefaultSecondary: '#DBDBFF',
    ZenRed: '#FF4040',
    ZenRedSecondary: '#FFE7E7',

    customStatusBackground: '#DBECFF',
    customStatusText: '#43548E',

    ZenOrange: '#F8B133',
    ZenOrangeSecondary: '#FFFAE1',

    ZenPurpleSecondary: '#E8D1FF',

    ZenGraySecondary: '#D0D5DD',

    RegularText: '#16192C',
    LighterText: '#6B7280',
    ZenGray2: '#ECEEF3',
    ZenPurple3: '#FBF6FF',

    ZenLightGray: '#F2F4F7',

    ZenPositiveText: '#1E7021',
    ZenPositive: '#29CC97',
    ZenPositiveSecondary: '#DAFFF3',

    ZenNegative: '#FF406E',
    ZenNegativeSecondary: '#FFD4DF',
    ZenProgress: '#FF9313',

    ZenPurpleSecondary2: '#F2E4FF',

    Gray3: '#F4F7F9',
    ZenTransparent: 'transparent',

    white: '#fff',
  },
};

export const dark = {
  ...themeHelper,
  colors: {
    primary: '#4851f4',
    backgroundColor: '#222',
    text: '#f8f8f8',
    sidebarBackground: '#FDFDFE',

    divider: '#E2E7EF',

    ZenDefault: '#2B2B7C',
    zenPurple: '#8927EF',

    zenGray: '#A0A0A0',

    ZenDefaultSecondary: '#DBDBFF',
    ZenPurpleSecondary: '#E8D1FF',

    ZenRed: '#FF4040',
    ZenRedSecondary: '#FFE7E7',

    customStatusBackground: '#DBECFF',
    customStatusText: '#43548E',

    ZenOrange: '#F8B133',
    ZenOrangeSecondary: '#FFFAE1',

    ZenGraySecondary: '#D0D5DD',

    ZenPurpleSecondary2: '#F4EBFF',

    RegularText: '#16192C',
    LighterText: '#6B7280',
    ZenGray2: '#ECEEF3',
    ZenPurple3: '#FBF6FF',

    ZenLightGray: '#F2F4F7',

    ZenPositiveText: '#1E7021',
    ZenPositive: '#29CC97',
    ZenPositiveSecondary: '#DAFFF3',

    ZenNegative: '#FF406E',
    ZenTransparent: 'transparent',
    ZenProgress: '#FF9313',
  },
};
