import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/common/loader';
import PageHeader from '../../../../components/page-header';
import UsersList from '../../../../components/users-list';
import { OrganisationContext } from '../../../../context/organisationContext';
import useDebounce from '../../../../helpers/useDebounceHook';
import { getUsers } from '../../../../store/features/userManagementSlice';
import { UsersWrapper } from '../../../../styles/page/settings.styled';

const Users = () => {
  const [search, setSearchText] = useState('');
  const [filter, setFilter] = useState({});
  const { t } = useTranslation();
  const { users } = useSelector(state => state.usersManagement);
  const dispatch = useDispatch();
  const [selectedPage, setSelectedPage] = useState(0);
  const debouncedSearch = useDebounce(search, 500);
  const [loading, setLoading] = useState(false);

  const getFilterValue = filter => {
    if (!filter.value?.id) {
      return {};
    }
    return { [filter.object.param]: filter.value?.id || '' };
  };
  const { setModal } = useContext(OrganisationContext);

  const fetchUsersList = (page = 0) => {
    setLoading(true);
    dispatch(getUsers({ params: { search: debouncedSearch, page: page, size: 13, ...getFilterValue(filter) } })).then(
      response => setLoading(false),
    );
  };

  useEffect(() => {
    setSelectedPage(0);
    fetchUsersList(0);
  }, [debouncedSearch, filter.value?.id]);

  useEffect(() => {
    fetchUsersList(selectedPage);
  }, [selectedPage]);

  const handleAddUser = () => {
    setModal({
      type: 'invite-user',
      content: {
        selectedPage,
      },
    });
  };
  return (
    <UsersWrapper className="flex-column items-start justify-start w-full">
      <PageHeader
        names={[
          { text: t('SETTINGS'), path: '/settings' },
          { text: t('SECURITY'), path: '/settings/security' },
          { text: t('USERS'), onClick: () => {} },
        ]}
        showAddNewBtn
        showFilter
        className="px-10 py-6"
        filterObject={['USER_STATUS']}
        showSearch
        filter={filter}
        setFilter={setFilter}
        searchText={search}
        onSearchChange={setSearchText}
        showPagination
        pagination={{
          selectedPage: selectedPage,
          totalPages: users?.total_pages || 0,
          setSelectedPage: setSelectedPage,
        }}
        addBtnPermission="USERS_MANAGE"
        onAddClick={() => handleAddUser()}
      />
      {loading ? (
        <Loader height={64} width={64} />
      ) : (
        <UsersList
          filter={filter}
          debouncedSearch={debouncedSearch}
          updateUsersList={() => fetchUsersList(selectedPage)}
        />
      )}
    </UsersWrapper>
  );
};

export default Users;
