import OverviewPage from '../overview';
import PlayerListPage from '../player-list';
import Settings from '../settings';
import Security from '../settings/security';
import Users from '../settings/security/users';
export const ROUTE_PATHS = [
  {
    id: 1,
    path: '/overview',
    Component: OverviewPage,
  },
  {
    id: 2,
    path: '/player-list',
    Component: PlayerListPage,
  },
  {
    id: 4,
    path: '/settings',
    Component: Settings,
  },
  {
    id: 5,
    path: '/settings/security',
    Component: Security,
  },
  {
    id: 6,
    path: '/settings/security/users',
    Component: Users,
    permission: ['USERS_MANAGE', 'USERS_VIEW', 'SETTINGS_VIEW'],
  },

  // {
  //   id: 22,
  //   path: '/settings/security/users/:id',
  //   Component: Profile,
  //   permission: ['USERS_MANAGE', 'USERS_VIEW'],
  // },
];
