import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import defaultFaviconIcon from './assets/images/favicon-image.ico';
import ReloadIcon from './assets/images/reload.svg';
import Button from './components/Elements/button/button';
import { LanguageProvider } from './context/LanguageContext';
import { ThemePreferenceContextProvider, initialThemeState } from './context/ThemePreferenceContext';
import { getLanguage } from './helpers/i18n';
import './index.css';
import { Router } from './routes/router';
import { getApplicationData, setApplicationData } from './store/features/applicationSlice';
import { init } from './store/features/reloadSlice';
import { CommonStyleWrapper } from './styles/Common.styled';
import { dark, light } from './styles/theme/index';
import './translations/i18n';

const themesMap = {
  light,
  dark,
};

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.reload);
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState(getLanguage());
  const [theme, setTheme] = useState(initialThemeState.theme);

  const onLanguageChange = lang => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  const updateFaviconImage = faviconImage => {
    if (!faviconImage) return;
    const faviconEl = document.getElementById('element-favicon-icon');
    const { url } = faviconImage || {};
    console.log('Favicon image', faviconEl, url);
    if (!url) {
      faviconEl.href = defaultFaviconIcon;
    } else {
      faviconEl.href = url;
    }
  };

  useEffect(() => {
    const fetchApplicationData = () => {
      dispatch(getApplicationData({ tag: 'ZPUKBACKOFFICE' }))
        .then(data => {
          const { images, name } = data;
          const { FAVICON } = images;
          updateFaviconImage(FAVICON);
          document.title = name;
        })
        .catch(() => {
          dispatch(setApplicationData({}));
          updateFaviconImage({});
          document.title = 'Zenterprize UK Backoffice';
        });
    };
    fetchApplicationData();
  }, []);

  return (
    <LanguageProvider value={{ onLanguageChange, language }}>
      <ThemePreferenceContextProvider value={{ theme, setTheme }}>
        <ThemeProvider theme={themesMap[theme]}>
          <CommonStyleWrapper />
          <div className={`App theme-${theme}`}>
            <Router />
            {state.updated && (
              <div className="fixed new-version-popup flex justify-center pxy-6">
                <div className="flex flex-column items-center card mt-20 px-12 py-8 refresh-view">
                  <label className="bold-text font-24">New version available!</label>
                  <label className="regular-text lighter-text text-center mt-2">
                    Please refresh the page to get access to new platform updates
                  </label>
                  <img className="mt-10 flex-1" src={ReloadIcon} />
                  <Button
                    label="Refresh"
                    className="primary mt-10"
                    borderRadius="16px"
                    width="100%"
                    size="large"
                    onClick={() => {
                      state.serviceWorkerRegistration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
                      dispatch(init());
                      window.location.reload();
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </ThemeProvider>
      </ThemePreferenceContextProvider>
    </LanguageProvider>
  );
};

export default App;
