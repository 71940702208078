import React from 'react';
import PlayerList from '../../components/PlayerList';

const PlayerListPage = ({ expanded }) => {
  return (
    <div
      className={`flex flex-1 ${expanded ? 'playerList-expand' : 'playerList-without-expand'}`}
      style={{ maxHeight: '100dvh', maxWidth: '100dvw', width: '100vh' }}>
      <PlayerList expanded={expanded} />
    </div>
  );
};

export default PlayerListPage;
