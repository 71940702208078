import ReactECharts from 'echarts-for-react';
import React from 'react';

const BarColumnChart = ({ seriesData, xaxisData, Tootltiptile }) => {
  const customTooltipFormatter = params => {
    const ageValue = params[0].axisValueLabel;
    const value = params[0].data;
    const SubtitleTooltip = Tootltiptile;
    return `
    <div  style="min-width:120px; text-align:center; border-radius: 12px">
          <p class="medium-text py-1 font-12" style="background-color: #E8D1FF; color: #8927EF ;  border-radius: 12px 12px 0 0;">
          ${ageValue}
          </p>
          <div class="px-2 py-1  flex-column">
            <label class="flex justify-center items-center flex-1 semibold-text font-12" >${value}</label>
            <label class="flex justify-center items-center flex-1 medium-text font-12" >${SubtitleTooltip}</label>

          </div>
        </div>`;
  };

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: customTooltipFormatter,
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      borderRadius: 12,
      borderWidth: 0,
      padding: 0,
    },
    grid: [
      {
        left: 0,
        top: 30,
        bottom: 50,
        width: '100%',
        containLabel: true,
      },
    ],
    xAxis: {
      data: xaxisData,
      axisLabel: {
        color: '#6B7280',
        fontSize: 9,
        interval: 0,
        fontWeight: 500,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
      },
    },
    yAxis: {
      axisLine: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          width: 2,
          color: 'rgba(0, 0, 0, 0.13)',
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        color: '#6B7280',
        fontWeight: 500,
        fontSize: 9,
        margin: 15,
      },
    },

    series: [
      {
        type: 'bar',
        barWidth: '61px',

        itemStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 0.9,
            colorStops: [
              { offset: 0, color: '#E8D1FF' },
              { offset: 1, color: '#FFDFDB' },
            ],
            globalCoord: false,
          },
          barBorderRadius: 4,
        },

        data: seriesData,
      },
    ],
  };

  return (
    <div>
      <ReactECharts option={option} height={250} width={'100%'} />
    </div>
  );
};

export default BarColumnChart;
