import React from 'react';
import { Manager, Popper, Reference } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import { Content, PopoverAnimationGlobalStyle } from './tooltip.styled';

export const TooltipStateless = props => {
  return (
    <Manager>
      <Reference>
        {({ ref }) => (
          <div
            ref={ref}
            className={props.className}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            style={props.style}>
            {props.children}
          </div>
        )}
      </Reference>

      <Popper modifiers={[{ name: 'offset', options: { offset: props.offset } }]} placement={props.placement || 'top'}>
        {({ ref, style, placement, arrowProps }) => (
          <>
            <CSSTransition classNames="tp-ds__popover" in={props.isOpen} timeout={200} unmountOnExit>
              <Content ref={ref} className="tooltip-content" data-placement={placement} style={style}>
                {props.content}
                <div data-popper-arrow id="arrow" {...arrowProps}></div>
              </Content>
            </CSSTransition>
          </>
        )}
      </Popper>

      <PopoverAnimationGlobalStyle />
    </Manager>
  );
};
