import React from 'react';
import AccountActivation from '../../components/AccountActivation';
import { AccountActivationWrapper } from '../../styles/invite/invite.styled';

const Activate = () => {
  return (
    <AccountActivationWrapper className="flex justify-center h-100-vh overflow-scroll">
      <AccountActivation />
    </AccountActivationWrapper>
  );
};

export default Activate;
