import styled from 'styled-components';

export const BoxWrapper = styled('div')`
  height: 88px;
  border: 1px solid ${({ theme }) => theme.colors.ZenGray2};

  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.zenPurple};

    .icon-fill {
      path {
        fill: ${({ theme }) => theme.colors.zenPurple};
      }
    }

    .icon-stroke {
      path {
        stroke: ${({ theme }) => theme.colors.zenPurple};
      }
    }
  }

  .subtitle-text {
    color: ${({ theme }) => theme.greyText2};
  }
`;
