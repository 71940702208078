import { createSlice } from '@reduxjs/toolkit';
import api from '../../store/services';

export const playerListSlice = createSlice({
  name: 'playerList',
  initialState: {},
  reducers: {
    setPlayerList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.playerList?.content, ...payload?.data?.content],
        };
        state.playerList = mergeData;
      } else {
        state.playerList = payload.data;
      }
    },
    setExportColumnsList: (state, { payload }) => {
      state.exportColumnList = payload;
    },
    setTemplateList: (state, { payload }) => {
      state.templateList = payload;
    },
    setTemplateList: (state, { payload }) => {
      state.templateList = payload;
    },
    updateTemplateList: (state, { payload }) => {
      const updatedList = state.templateList.map(data => (data?.id === payload?.id ? payload : data));
      state.templateList = updatedList;
    },
    removeTemplate: (state, { payload }) => {
      const updatedList = state.templateList.filter(data => data?.id !== payload);
      state.templateList = updatedList;
    },
    addTemplateList: (state, { payload }) => {
      state.templateList = [...state.templateList, { ...payload }];
    },
  },
});

export const getPlayerList = payload => async dispatch => {
  try {
    const { data } = await api.get('/players', { params: payload?.params });
    dispatch(setPlayerList({ data: data, merge: payload.merge }));
    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getExportColumnsList = payload => async dispatch => {
  try {
    const { data } = await api.get(`/players/export_columns`);
    dispatch(setExportColumnsList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getTemplateList = () => async dispatch => {
  try {
    const { data } = await api.get(`/players/templates`);
    dispatch(setTemplateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateTemplate = payload => async dispatch => {
  try {
    const { request, templateId } = payload;
    const { data } = await api.put(`/players/templates/${templateId}`, request);
    dispatch(updateTemplateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const deleteTemplateApi = payload => async dispatch => {
  try {
    const { templateId } = payload;
    const { data } = await api.delete(`/players/templates/${templateId}`);
    dispatch(removeTemplate(templateId));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createTemplateApi = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/players/templates`, request);
    dispatch(addTemplateList(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportPlayerListApi = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/players/export`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const downloadPlayerDetailsCSVFile = payload => async () => {
  try {
    const { export_id } = payload;
    const { data } = await api.get(`/players/export/${export_id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {
  setPlayerList,
  setExportColumnsList,
  setTemplateList,
  updateTemplateList,
  removeTemplate,
  addTemplateList,
} = playerListSlice.actions;
export default playerListSlice.reducer;
