import { createSlice } from '@reduxjs/toolkit';
import { getItemFromLocalStorage } from '../../helpers/localstorage';
import api from '../services';

export const clientOrgSlice = createSlice({
  name: 'clientOrg',
  initialState: {
    clientOrgData: [],
  },
  reducers: {
    setClientOverview: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.clientOverview?.content, ...payload?.data?.content],
        };
        state.clientOverview = mergeData;
      } else {
        state.clientOverview = payload.data;
      }
    },
    setBeneficiaryClient: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = {
          ...payload?.data,
          content: [...state?.beneficiaryClient?.content, ...payload?.data?.content],
        };
        state.beneficiaryClient = mergeData;
      } else {
        state.beneficiaryClient = payload.data;
      }
    },
  },
});

export const getOrganizationNetworksClient = payload => async dispatch => {
  try {
    const org = getItemFromLocalStorage('user').organizations[0];
    const { data } = await api.get(
      `/organizations/${payload?.organization_id ? payload?.organization_id : org?.id}/network`,
      { params: payload.param },
    );
    dispatch(setClientOverview({ data: data, merge: payload.merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const getBeneficiaryClient = payload => async dispatch => {
  try {
    const client = getItemFromLocalStorage('client');
    const { data } = await api.get(`/organizations/${payload?.client_id ? payload?.client_id : client?.id}/network`, {
      params: payload.param,
    });
    dispatch(setBeneficiaryClient({ data: data, merge: payload.merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getOrganizationNetworks =
  ({ organization_id, params }) =>
  async dispatch => {
    try {
      const { data } = await api.get(`/organizations/${organization_id}/network`, { params: params });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const { setClientOverview, setBeneficiaryClient } = clientOrgSlice.actions;
export default clientOrgSlice.reducer;
