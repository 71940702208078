import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import CheckedIcon from '../../../assets/images/check-done.svg';

const Checkbox = ({ onChange = () => {}, checked = false, showRequiredError }) => {
  return (
    <CheckboxWrapper className="flex items-center">
      <input
        className={classNames(
          'cursor checkbox',
          checked && 'checked',
          showRequiredError && !checked && 'required-error',
        )}
        type="checkbox"
        checked={checked}
        onChange={() => onChange(!checked)}
      />
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled('div')`
  .checkbox {
    appearance: none;
    min-width: 16px;
    min-height: 16px;
    outline: none;
    margin: 0;
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
    border-radius: 4px;
  }

  .checked {
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    border-color: ${({ theme }) => theme.colors.zenPurple};
    position: relative;
  }

  .checked::before {
    content: url(${CheckedIcon});
    transform: scale(0.7);
    position: absolute;
    right: -1px;
    top: -1px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export default Checkbox;
