import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DonutPieChart from '../Charts/DonutPieChart';

const LengthSupport = () => {
  const { supportStats } = useSelector(state => state?.overview);

  const { t } = useTranslation();

  const dataSet = [
    {
      key: 'this_month',
      value: supportStats?.this_month,
      name: `First draw entrants - last 30 day period - ${supportStats?.this_month}`,
    },
    {
      key: 'one_year',
      value: supportStats?.one_year,
      name: `Under 1 year - ${supportStats?.one_year}`,
    },
    {
      key: 'two_years',
      value: supportStats?.two_years,
      name: `1 to 2 years - ${supportStats?.two_years}`,
    },
    {
      key: 'three_years',
      value: supportStats?.three_years,
      name: `2 to 3 years - ${supportStats?.three_years}`,
    },
    {
      key: 'long_term',
      value: supportStats?.long_term,
      name: `3 years or older - ${supportStats?.long_term}`,
    },
  ];

  return (
    <div
      className={`border radius-4 pxy-4 flex-column flex-1 h-full length-support-respo`}
      style={{ height: 300, width: 544 }}>
      <label className="font-16 medium-text mb-4">{t('LENGTH_OF_SUPPORT')}</label>
      <DonutPieChart dataSet={dataSet} Tootltiptile={'Support'} />
    </div>
  );
};

export default LengthSupport;
