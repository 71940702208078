import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    applicationData: {},
    applicationDataLoading: true,
  },
  reducers: {
    setApplicationData: (state, { payload }) => {
      state.applicationData = payload;
      state.applicationDataLoading = false;
    },
  },
});

export const getApplicationData = payload => async dispatch => {
  const { tag } = payload || {};
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/applications/${tag}`);
    dispatch(setApplicationData(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setApplicationData } = applicationSlice.actions;
export default applicationSlice.reducer;
