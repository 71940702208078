import React, { useState } from 'react';
import { TooltipStateless } from './tooltip-stateless';

export const Tooltip = props => {
  const [isOpen, setIsOpen] = useState(false);

  const { shouldOpen = true, isAlwaysOpen = false } = props;

  const handleMouseEnter = () => {
    if (!isOpen && !isAlwaysOpen) {
      setTimeout(() => setIsOpen(true), props.showDelay);
    }
  };

  const handleMouseLeave = () => {
    if (!isAlwaysOpen) {
      setIsOpen(false);
    }
  };

  return (
    <TooltipStateless
      className={props.className}
      content={props.content}
      isOpen={isOpen || isAlwaysOpen}
      offset={props.offset}
      onMouseEnter={!isAlwaysOpen && shouldOpen ? handleMouseEnter : undefined}
      onMouseLeave={!isAlwaysOpen ? handleMouseLeave : undefined}
      placement={props.placement}
      style={props.style}>
      {props.children}
    </TooltipStateless>
  );
};
